import React from 'react';
import { useState, useContext } from "react";
import { ThemeContext } from '../../App';

export default function FilledButton(props) {
    const [isHover, setIsHover] = useState(false);
    const theme = useContext(ThemeContext);

    const wrapper = {
        height:40,
        width:100,
        paddingLeft:24,
        paddingRight:24,
        borderRadius:8,
        color:theme.onPrimary,
        backgroundColor: theme.primary,
        fontSize:24,
        lineHeight:'40px',
        textAlign:'center',
        cursor:'pointer'
    };

    const mouseEnter = () => {
        setIsHover(true);
    };

    const mouseLeave = () => {
        setIsHover(false);
    };

    return (
        <div 
            style={Object.assign(wrapper,props.style)} 
            onClick={props.onSelect}
            onMouseEnter={mouseEnter}
            onMouseLeave={mouseLeave}
        >
            {props.children}
        </div>
    )
}