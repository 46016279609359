import React from 'react';
import { useState, useContext, useEffect, useRef } from "react";
import { Link,useSearchParams,useNavigate } from "react-router-dom";

import AppBar from '../../components/AppBar.js';
import Drawer from '../../components/Drawer.js';
import MenuButton from '../../components/buttons/Menu.js';
import MenuItem from '../../components/menu/MenuItem.js';
import SearchBar from '../../components/SearchBar.js';
import Shadow from '../../components/Shadow.js';
import Spacer from '../../components/menu/Spacer.js';
import Tables from "../../components/tables/Tables.js";

import "../style.css";

import { AuthContext } from '../../App.js';
import { ThemeContext } from '../../App.js';
import { urlContext } from '../../App.js';
import { keyContext } from '../../App.js';

var online = 0;
var offline = 0;
var enabled = 0;

export default function SitesTable(props) {
    const auths = useContext(AuthContext);
    const theme = useContext(ThemeContext);
    const asURL = useContext(urlContext);
    const asKey = useContext(keyContext);

    const elemRef = useRef([]);

    const [reading, setReading] = useState("stage");
    const [sites, setSites] = useState([]);
    const [title, setTitle] = useState("");
    const [shadow, setShadow] = useState(false);
    const [drawer, setDrawer] = useState(false); 
    const [queryParameters] = useSearchParams();
    const navigate = useNavigate();

    const url = window.location.href;
    const custID = url.split("=")[1];

    {console.log("Sites:customer:",custID)}
    {console.log("Sites:url:",asURL)}
    {console.log("Sites:key:",asKey)}

    useEffect(() => {
        getSites()
    },[props]);

    function getSites(){
        const queryStr = {
            query: `query GetSites($id:ID!) {
                getSites(id:$id) {
                    customer {
                        abbreviation,
                        name
                    },
                    sites {
                        pk,
                        sk,
                        active,
                        enabled,
                        message {
                            id,
                            thingName,
                            timestamp,
                            ina219 {
                              current,
                              voltage
                            },
                            lte {
                              rssi
                            },
                            senix {
                              mm,
                              temp
                            }
                        },
                        name,
                        readings {
                            name,
                            type,
                            thresholds {
                              color,
                              name,
                              value
                            },
                            min,
                            max,
                            elevation,
                            MLLW,
                            unit
                        },
                        tags {
                            dashboard
                            summary
                            timeout
                        },
                        device
                    }
                }
            }`,
            variables: {
                "id":custID
            }
        }
        fetch(asURL,{
            method:'POST',
            headers: {
                "Content-Type":"application/graphql",
                "x-api-key":asKey
            },
            body:JSON.stringify(queryStr)
        })
        .then(resp => resp.json())
        .then(resp => {
            var tmpSites;

            online = 0;
            offline = 0;
            enabled = 0;

            console.log("resp:",resp.data.getSites);

            setTitle(resp.data.getSites.customer.abbreviation);

            // Calculate totals
            resp.data.getSites.sites.forEach(site => {
                site["visible"] = 'block';

                if(site.enabled) {
                    enabled += 1;

                    if(site.active) {
                        online += 1;
                    } else {
                        offline += 1;
                    }
                }
            })
            
            tmpSites = resp.data.getSites.sites.sort((a, b) => {
                if(a.name < b.name) {
                    return -1;
                } 

                if (a.name > b.name) {
                    return 1;
                }
                
                return 0;
            });

            console.log("Sites:",tmpSites);
            setSites(tmpSites);
        })
    };

    const wrapper = {
        width:'100%',
        marginBottom:40
    }

    const appBarStyle = {
        position:'fixed',
        top:0,
        zIndex:1
    }

    const menuButtonStyle = {
        position:'absolute',
        top:4,
        left:5,
        fontSize:21,
        textDecoration: 'none',
        color:'rgb(32,32,32)'
    }

    const searchStyle = {
        position:'fixed',
        top:96,
        zIndex:1
    }

    const statusStyle = {
        position:'fixed',
        top:48,
        left:0,
        right:0,
        height:48,
        background:'rgb(255,255,255)',
        lineHeight:"48px",
        borderBottom:"solid 1px rgb(240,240,240)",
        textAlign :'center',
        zIndex:1
    }

    function onClear(e) {
        for(var i in elemRef.current) {
            elemRef.current[i].style.display = "block";
        }
    }

    function onMenu(e) {
        if(drawer) {
            setDrawer(false);
            setShadow(false);
        } else {
            setDrawer(true);
            setShadow(true);
        }
    }

    function onSearch(e) {
        var tmp = sites;

        console.log("onSearch:",e.target.value);
        for(var i in sites) {
            if(sites[i].site.name.toLowerCase().startsWith(e.target.value.toLowerCase())) {
                elemRef.current[i].style.display = "block";
                console.log(elemRef.current[i]);
            } else {
               elemRef.current[i].style.display = "none";
            }
        }
    }

    function onShadow() {
        setShadow(false);
        setDrawer(false);
    }

    return(
        <div className="sites" onClick={props.onClick}>
        <Shadow visible = {shadow} onClose={onShadow}/>
        <Drawer 
                visible={drawer} 
                anchor={"left"}
                title = "Menu"
                onClose={onMenu}
            >
                <MenuItem enabled={true} onSelect={() => navigate("/")}>Home</MenuItem>
                <Spacer></Spacer>
                <MenuItem enabled={true} onSelect={() => navigate("/Sites/Grid?id=" + custID)}>Grid</MenuItem>
                <MenuItem enabled={false}>Table</MenuItem>

            </Drawer>

            <AppBar title={title + " Sites"} style={appBarStyle}>
                <MenuButton 
                    style={menuButtonStyle}
                    onSelect={onMenu}
                    >
                </MenuButton>
            </AppBar>
            <div className="statusBar" style={statusStyle}>
                <span style={{fontSize:21, marginLeft:10, marginRight:10}}>Total:</span>
                <span style={{fontSize:21}}>{sites.length}</span>
                <span style={{fontSize:21, marginLeft:10, marginRight:10}}>Online:</span>
                <span style={{fontSize:21, color:'rgb(100, 180, 0)'}}>{online}</span>
                <span style={{fontSize:21, marginLeft:10, marginRight:10}}>Offline:</span>
                <span style={{fontSize:21, color:'rgb(255, 0, 0)'}}>{offline}</span>
            </div>
            <SearchBar onChange={onSearch} onClear={onClear} style={searchStyle}></SearchBar>
            {Tables(reading,sites)}
        </div>
    )
};