import React from 'react';
import { useState, useContext } from "react";
import { ThemeContext } from '../App';

export default function DateField(props) {
    const [isHover, setIsHover] = useState(false);
    const [isFocus, setIsFocus] = useState(false);
    const [isEmpty, setIsEmpty] = useState(true);
    const [value, setValue] = useState("");
    const theme = useContext(ThemeContext);

    const wrapper = {
        position:'relative'
    };

    var inputStyle = {
        height: 48,
        width: "100%",
        border: 'none',
        borderBottom: "1px solid rgb(196,196,196)",
        boxSizing: 'border-box',
        fontFamily: 'Helvetica',
        fontSize: 16
    }

    const labelStyle = {
        position: 'absolute',
        top: 0,
        left: 0,
        display: 'flex',
        pointerEvents: 'none',
        fontFamily: 'Helvetica',
        fontSize: 16
    }

    const textStyle = {
        fontFamily: 'Helvetica',
        paddingLeft: 4,
        paddingRight:4,
        transition: 'all 0.15s ease-out',
        fontSize: isFocus ? 12 : 16,
        transform: isFocus ? 'translate(0, -150%)': 'none',
        backgroundColor: isFocus ? 'white' : 'white',
        color: isFocus ? theme.primary : 'gray'
    }

    const onFocus = () => {
        setIsFocus(true);
        setIsEmpty(true);
    };

    const onBlur = (e) => {
        console.log(e.target.value);
        if(e.target.value != "") {
            setIsFocus(true);
            setIsEmpty(false);
            console.log("value not empty")
        } else {
            setIsFocus(false);
            console.log("value empty")
        }
    };

    const onChange = (e) => {
        setValue(e.target.value);
    }

    return (
        <div className="DateField" style={Object.assign(wrapper,props.style)} >
            <input
                value={props.value}
                style={inputStyle}
                type="date"
                name="DateField"
                onFocus={onFocus}
                onBlur={props.onBlur}
                onChange={props.onChange}
            />
            <label style={labelStyle} id="DateField-Label">
                <div style={textStyle}>{props.children}</div>
            </label>
        </div>
    )
}
