import React from 'react';
import { useState } from "react";
import CloseButton from '../components/buttons/CloseButton.js';
import Spacer from '../components/menu/Spacer.js';
import "./style.css";

export default function Drawer(props) {
    const [isHover, setIsHover] = useState(false);

    const drawerStyle = {
    }

    const titleStyle = {
        fontSize:24,
        fontWeight:'bold',
        textAlign:"left",
        marginLeft:10
    }

    const menuButtonStyle = {
        position:'absolute',
        top:5,
        right:5
    }

    switch (props.anchor) {
        case 'left':
            if(props.visible) {
                drawerStyle.left = 0; 
            } else {
                drawerStyle.left = -250;
            }
            break;

        case 'right':
            if(props.visible) {
                drawerStyle.right = 0; 
            } else {
                drawerStyle.right = -250;
            }
            break;
        default:
            alert("unknown anchor")
    }

    return(
        <div className={"drawer"} style={drawerStyle}>
            <div style={titleStyle}>{props.title}</div>
            <CloseButton
                style={menuButtonStyle}
                onSelect={props.onClose}
            >
            </CloseButton>
            <Spacer></Spacer>
            {props.children}
        </div>
    )
}