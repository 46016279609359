import React from 'react';
import { useState } from "react";


export default function AddressLabel(props) {

    const wrapper = {
        width:400,
        fontSize:14,
        marginTop:20
    }

    const title = {
        marginBottom:5,
        color:"rgb(92,92,92)"
    }

    const addr1 = {
        fontWeight:550
    }

    const addr2 = {
        fontWeight:550
    }

    const state = {
        fontWeight:550
    }

    const city = {
        fontWeight:550
    }

    const zip = {
        fontWeight:550,
        marginLeft:5
    }

    return(
        <div id="LocationLabel" style={Object.assign(wrapper,props.style)} >
            <div id="title" style={title}>Address</div>
            <div id="address_1" style={addr1}>{props.address.address_1}</div>
            <div id="address_2" style={addr2}>{props.address.address_2}</div>
            <span id="city" style={city}>{props.address.city}</span>
            <span>,</span>
            <span id="state" style={state}>{props.address.state}</span>
            <span id="zip" style={zip}>{props.address.zip}</span>
        </div>
    )
}