import React from 'react';
import { useState } from "react";

export default function Drawer(props) {
  const spacerStyle = {
    height:1, 
    // width:'90%',
    margin:'auto',
    marginTop:10,
    marginBottom:10,
    marginLeft:0,
    marginRight:0,
    backgroundColor:'rgb(220,220,220)'
  }

  return (
    <div id="spacer" style={spacerStyle}></div>
  )
}
