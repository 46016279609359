import React from 'react';
import { useState } from "react";
import 'primeicons/primeicons.css';

export default function ClearButton(props) {
    const [isHover, setIsHover] = useState(false);

    const wrapper ={
        width:40,
        height:40,
        borderRadius:'50%',
        backgroundColor: isHover ? 'rgba(192,192,192,0.5)' : 'transparent',
    };

    const icon = {
        fontSize:24,
        fontWeight:500,
        marginTop:6,
        marginLeft:10,
        color:'rgb(128,128,128)'
    }

    const mouseEnter = () => {
        setIsHover(true);
    };

    const mouseLeave = () => {
        setIsHover(false);
    };
    
    return(
        <div 
            style={Object.assign(wrapper,props.style)} 
            onClick={props.onSelect}
            onMouseEnter={mouseEnter}
            onMouseLeave={mouseLeave}
        >
            <i className="pi pi-times" style={icon}></i>
            {/* <ClearIcon style={icon} /> */}
        </div>

    )
}