import React from 'react';
import { useState, useContext } from "react";
import { ThemeContext } from '../../App';

export default function PrecipTypeCard(props) {
    const theme = useContext(ThemeContext);
    const template = "linear-gradient(to top, dodgerblue <pct>%, rgb(240,240,240) <pct>%)" 

    const wrapper = {
        position:'relative',
        width:200,
        height:100,
        borderRadius:4,
        padding:4,
        backgroundColor:'rgb(255,255,255)',
        boxSizing: 'border-box',
        boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.26)"
    }

    const dateStyle = {
        width:'100%',
        fontSize:12,
        textAlign:'center',
        marginTop:5,
        color:'rgb(128,128,128)'
    }

    const levelStyle = {
        display:'inline',
        fontSize:24,
        fontWeight:400,
        marginTop:5
    }

    const valueWrapper = {
        position:'relative',
        width:'100%',
        textAlign:'center',
        marginTop:10
    }

    const nameStyle = {
        fontSize:14,
        fontWeight:'bold',
        textAlign:'left'
    }

    const activeStyle = {
        position:'absolute',
        fontSize:14,
        top:5,
        right:5
    }

    const unitStyle = {
        display:'inline',
        fontSize:16,
        marginLeft:5
    }

    const statusStyle = {
        width:'100%',
        fontSize:12,
        textAlign:'center',
        color:'rgb(128,128,128)'
    }

    var barStyle = {
        position:'absolute',
        top:5,
        bottom:5,
        left:5,
        width:10,
        borderRadius:5,
        padding:4,
        boxSizing: 'border-box',
        // background: "rgb(240,240,240)",
        background:"linear-gradient(to top, dodgerblue 50%, rgb(240,240,240) 50%)"
    }

    const maxStyle = {
        position:"absolute",
        top:5,
        left:18,
        fontSize:10,
        color:"rgb(128,128,128)"
    }

    const minStyle = {
        position:"absolute",
        bottom:5,
        left:18,
        fontSize:10,
        color:"rgb(128,128,128)"
    }

    var type = props.type;

    switch(type) {
        case 0:
            type = "No Rain";
            break;

        case 60:
            type = "Rain";
            break;

        case 67:
            type = "Freezing Rain";
            break;

        case 69:
            type = "Sleet";
            break;

        case 70:
            type = "Snow";
            break;
    
        case 90:
            type = "Hail";
            break;
        
        default:
            console.log("Unknown type:",type)
            type = "unknown";
            break;
    }

    return (
        <div style={wrapper} id="precipType">
            <div style={nameStyle}>{props.name}</div>
            <div style={valueWrapper}>
                <div style={levelStyle}>{type}</div>
            </div>
            <div style={statusStyle}>{props.status}</div>
        </div>
    )
}