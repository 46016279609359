import React from 'react';
import { useState } from "react";
import '../style.css';

export default function MenuSubTitle(props) {
  const [hover, setHover] = useState(false);
  const [enabled, setEnabled] = useState(false);

  return (
    <div
      className="menu subTitle"
    >
      {props.children}
    </div>
  )
}