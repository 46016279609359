import React from 'react';

export default function Shadow({visible,onClose}) {
    var shadowStyle = {
        position:'absolute',
        top:0,
        bottom:0,
        left:0,
        right:0,
        backgroundColor:'rgba(0,0,0,0.2)',
        display:'none',
        zIndex:10
    }

    if(visible) {
        shadowStyle.display = 'block';
    } else {
        shadowStyle.display = 'none';
    }
    return(
        <div id="shadow" style={shadowStyle} onClick={onClose} >
        </div>
    )
}