import React from 'react';
import {useState, useContext} from 'react';
import CloseButton from '../components/buttons/CloseButton.js';

import { ThemeContext } from '../App';

function Spacer(props) {

  const wrapper = {
    position:"absolute",
    height:0.5,
    width:'99%',
    top:40,
    left: "50%",
    transform: "translate(-50%)",
    background:"rgb(220,220,220)"
  }

  return (
    <div style={wrapper}></div>
  )
}

export default function Dialog(props) {
  const theme = useContext(ThemeContext);
  const [visible, setVisible] = React.useState(false)

  var wrapper = {
    position:'absolute',
    backgroundColor: "white",
    height:"75%",
    width:"75%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: 'none',
    borderRadius:4,
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.26)"
  };
  
  const closeStyle = {
    float:'right',
    marginTop:4,
    // marginRight:20
  }

  const containerStyle = {
    padding:20
  }

  var titleStyle = {
    // width:"100%",
    height:48,
    lineHeight:"48px",
    fontSize:21,
    fontWeight:'bold',
    paddingLeft:20,
    backgroundColor: "rgba(240, 240, 240,0.5)",
    borderBottom: "solid 0.5px rgba(240, 240, 240,1)"
  }

  function onClick(e) {
    setVisible(false);
  }

  wrapper.display = props.visible ? "block" : "none";

  return (
    <div id="dialog" style={Object.assign(wrapper,props.style)} >
      <div id="title" style={titleStyle}>
        {props.title}
        <CloseButton size="32px" style={closeStyle} onSelect={props.onClose}></CloseButton>
      </div>
      <div id="container" style={containerStyle}>
        {props.children}
      </div>
    </div>
  )
}