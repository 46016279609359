import React from 'react';
import {useState, useContext} from 'react';
import SearchField from '../components/SearchField.js';

import { ThemeContext } from '../App';

export default function SearchBar(props) {
  const theme = useContext(ThemeContext);

  const wrapper = {
    backgroundColor: theme.secondary,
    position:0,
    width:'100%',
    height:48,
    lineHeight:"48px",
    borderBottom:'solid 1px rgb(240,240,240)'
  };

  const fieldStyle = {

  }

  return (
    <div id="searchBar" style={Object.assign(wrapper,props.style)}>
      <SearchField style={fieldStyle} onChange={props.onChange} onBlur={props.onBlur} onClear={props.onClear}></SearchField>
    </div>
  )
}