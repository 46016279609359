import React from 'react';
import {useState, useContext} from 'react';
import { ThemeContext } from '../App';
import "./style.css";

export default function AppBar(props) {
  const [isProfileHover, setIsProfileHover] = useState(false)
  const [isHelpHover, setIsHelpHover] = useState(false)
  const [isMenuHover, setIsMenuHover] = useState(false)
  const theme = useContext(ThemeContext);

  const titleStyle = {
    color:theme.onPrimary,
    lineHeight:'48px',
    textAlign:"center",
    overflow:"hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  }

  const updateStyle = {
    textAlign:"center",
    color:theme.onPrimary
  }

  return (
    <div className="appBar" id="appBar" style={{backgroundColor:theme.primary}}>
      <div id="title" style={titleStyle}>{props.title}</div>
      {props.children}
    </div>
  )
}