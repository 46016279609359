import React from 'react';
import { useState, useContext, useRef } from "react";
import ClearButton from '../components/buttons/ClearButton.js'
import { ThemeContext } from '../App';

export default function SearchField(props) {
    const [isHover, setIsHover] = useState(false);
    const [isFocus, setIsFocus] = useState(false);
    const [isEmpty, setIsEmpty] = useState(true);
    const [value, setValue] = useState("");
    const theme = useContext(ThemeContext);
    const inputRef = useRef(null);

    const wrapper = {
        position:'relative',
        margin:'auto'
    };

    var inputStyle = {
        position:'relative',
        display:'inline',
        top:1,
        height: 40,
        width: "100%",
        border: 'none',
        borderRadius: 4,
        boxSizing: 'border-box',
        padding: 16,
        fontFamily: 'Helvetica',
        fontSize: 21,
        backgroundColor:'rgb(240,240,240)',
        color:'rgb(128,128,128)'
    }

    const clearStyle = {
        position:'absolute',
        right:5,
        top:4
    }

    const onFocus = () => {
        console.log("Focus:");
        setIsFocus(true);
        setIsEmpty(true);
    };

    const onBlur = (e) => {
        console.log("onBlur:",inputRef.current.value);
        if(e.target.value != "") {
            setIsFocus(true);
            setIsEmpty(false);
            console.log("value not empty")
        } else {
            setIsFocus(false);
            console.log("value empty")
        }
    };

    const onKeyUp = (e) => {
        if(e.target.value == 13) console.log("enter");
        console.log(e.target.value);
        // setValue(e.target.value);
    }

    const onClear = (e) => {
        inputRef.current.value = "";
        props.onClear();
    }

    return (
        <div id="SearchField" style={Object.assign(wrapper,props.style)} >
            <input
                style={inputStyle}
                type="text"
                id="SearchField"
                placeholder="Search Criteria"
                name="SearchField"
                // value={value}
                onFocus={onFocus}
                onBlur={onBlur}
                onKeyUp={props.onChange}
                // onChange={props.onChange}
                ref={inputRef}
            />
            <ClearButton style={clearStyle} onSelect={onClear}></ClearButton>
        </div>
    )
}
