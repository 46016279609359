import React from 'react';
import { useState, useContext } from "react";
import { ThemeContext } from '../../App';

export default function StatusCard(props) {
    const [isOnline, setIsOnline] = useState("offline")
    const theme = useContext(ThemeContext);
    const template = "linear-gradient(to top, dodgerblue <pct>%, rgb(240,240,240) <pct>%)" 

    const wrapper = {
        position:'relative',
        width:200,
        height:100,
        borderRadius:4,
        padding:4,
        backgroundColor:'rgb(255,255,255)',
        boxSizing: 'border-box',
        boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.26)"
    }

    const dateStyle = {
        width:'100%',
        fontSize:12,
        textAlign:'center',
        marginTop:5,
        color:'rgb(128,128,128)'
    }

    const levelStyle = {
        display:'inline',
        fontSize:32,
        fontWeight:'bold',
        marginTop:5
    }

    const valueWrapper = {
        position:'relative',
        width:'100%',
        textAlign:'center',
        marginTop:5
    }

    const titleStyle = {
        fontSize:14,
        fontWeight:'bold',
        textAlign:'left'
    }

    const activeStyle = {
        position:'absolute',
        fontSize:14,
        top:5,
        right:5
    }

    const unitStyle = {
        display:'inline',
        fontSize:16,
        marginLeft:5
    }

    const statusStyle = {
        width:'100%',
        fontSize:12,
        textAlign:'center',
        color:'rgb(128,128,128)'
    }

    var barStyle = {
        position:'absolute',
        top:5,
        bottom:5,
        left:5,
        width:10,
        borderRadius:5,
        padding:4,
        boxSizing: 'border-box',
        background:"rgb(240,240,240)"
    }

    const maxStyle = {
        position:"absolute",
        top:5,
        left:18,
        fontSize:10,
        color:"rgb(128,128,128)"
    }

    const minStyle = {
        position:"absolute",
        bottom:5,
        left:18,
        fontSize:10,
        color:"rgb(128,128,128)"
    }

    const thresholdStyle = {
        position:"relative",
        width:"100%",
        textAlign:"center",
        fontSize:14,
        color:"rgb(128,128,128)"
    }

    console.log("StatusCard:props:",props);

    var max = props.param.max;
    var min = props.param.min;
    var value = props.value.toFixed(2);


    if(props.param.unit == "mm") {
        max = (props.param.max / 304.8).toFixed(2);
        min = (props.param.min / 304.8).toFixed(2);
        value = (props.value / 304.8).toFixed(2);
    }

    if(max != 0 && min != 0 ) {
        var pct = ((value - min) / (max - min)) * 100
        console.log("StatusCard:pct",pct);

        if(pct < 0) pct = 0;
    
        barStyle.background = template.replaceAll("<pct>",pct);    
    }

    console.log("StatusCard:props:value",value);

    return (
        <div style={wrapper} id="statusCard">
            <div id="title" style={titleStyle}>{props.name}</div>
            {/* <div id="bar" style={barStyle}></div>
            <div id="max" style={maxStyle}>{max}</div>
            <div id="min" style={minStyle}>{min}</div> */}
            <div style={valueWrapper}>
                <div style={levelStyle}>{value}</div>
                <div style={unitStyle}>{props.unit}</div>
            </div>
            <div style={thresholdStyle}>{""}</div>
            <div style={statusStyle}>{props.status}</div>
        </div>
    )
}