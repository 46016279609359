import React from 'react';
import { useState, useContext, useEffect } from "react";
import { ThemeContext } from '../../App.js';
import "../style.css";

import Chart from "react-apexcharts";
import ApexCharts from 'apexcharts';

export default function AirTemp(props) {
  const theme = useContext(ThemeContext);

  var options = {
    chart: {
      id: "airTempChart", 
      redrawOnWindowResize: true, 
      animations:{enabled:true},
      toolbar:{
        show:true,
        tools:{
          download:false
        },
        export:{
          csv:{
            filename:"stageReadings",
            headerCategory: 'Date',
            headerValue: 'stage',
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString()
            }
          }
        }
      }
    },
    title: {
      text:"Air Temperature", 
      align: 'center',
      style:{fontSize:'21px'}
    },
    markers: {
      hover: {size: 10}
    },
    dataLabels: {
      enabled:false
    },
    colors:['#0080FF'],
    tooltip: {x:{format:"MM-dd HH:mm:ss"}},
    xaxis: {type: 'datetime',labels: {format: 'MM-dd HH:mm',datetimeUTC:false}},
    yaxis: {
        labels: {
            formatter: function (value) {
              return value.toFixed(1) + " F";
            }
        },
        max:props.reading.max,
        min:props.reading.min
    },
    zoom:{enabled:true,type:"xy",autoScaleYaxis:true},
    stroke:{
        width:2
    },
    annotations:{
      yaxis:[]
    }
  }

  var series = [{name:'stage',data:[]}];

  {console.log("airTempGraph:site:",props.site)}
  {console.log("airTempGraph:reading:",props.reading)}
  // {console.log("airTempGraph:messages:",props.messages)}

  // Add Thresholds
  props.reading.thresholds.forEach(threshold => {
    // console.log("Threshold:",threshold);
    var obj = {
            y:threshold.value,
            borderColor:threshold.color,
            fillColor:threshold.color,
            strokeDashArray:0,
            label: {
                borderColor:threshold.color,
                style: {
                    color: '#FFFFFF',
                    background:threshold.color
                },
                text: threshold.name + " (" + (threshold.value).toFixed(1) + " F)",
                textAnchor:'start',
                position:'left',
                offsetX:10
            },
    }
    options.annotations.yaxis.push(obj);
  })

  // Add data points
  props.messages.forEach(message => {
    var dt = message.msg.timestamp * 1000;
    var temp;

    if(message.msg.lufft!== null) {
      temp = ((message.msg.lufft.airTemp) * 1.8) + 32;      
      series[0].data.push({x:dt,y:temp});
      
    } else if(message.msg.senix!== null) {
      temp = ((message.msg.senix.temp /10) * 1.8) + 32;      
      series[0].data.push({x:dt,y:temp});
    }
  })

  return (
    <Chart 
      className="chart"
      type="area"
      width="100%"
      height="300px"
      options={options}
      series={series}
    >
    </Chart>
  )
}

