import React from 'react';
import { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import BackButton from '../components/buttons/BackButton.js';
import Cards from "../components/cards/readings/Cards.js";
import DateField from '../components/DateField.js';
import Drawer from '../components/Drawer.js';
import FilledButton from '../components/buttons/FilledButton.js';
import FilterIconButton from '../components/buttons/FilterIconButton.js';
import Graphs from "../components/graphs/Graphs.js";

import Grid from '../components/Grid.js';
import GridItem from '../components/GridItem.js';
import InfoIconButton from '../components/buttons/InfoIconButton.js';
import RadioButton from '../components/RadioButton.js';
import Shadow from '../components/Shadow.js';

import { AuthContext } from '../App';
import { ThemeContext } from '../App';
import { urlContext } from '../App';
import { keyContext } from '../App';

const initSite = {
    active:false,
    name:"",
    description:"",
    sensors:[
        {
            make:"",
            model:"",
            value:0
        }
    ],
    location:{
        latitude:0,
        longitude:0
    },
    parameters:[],
    address:{
        address_1:"",
        address_2:"",
        city:"",
        state:"",
        zip:""
    }
}

var lastUpdate = "-/-/- -:-:-"

export default function Senix(props) {
    const auths = useContext(AuthContext);
    const theme = useContext(ThemeContext);
    const asURL = useContext(urlContext);
    const asKey = useContext(keyContext);

    const [filterState, setFilterState] = useState(false);
    const [shadowState, setShadowState] = useState(false);
    const [site, setSite] = useState(initSite);
    const [events, setEvents] = useState([]);
    const [title, setTitle] = useState("-");

    const navigate = useNavigate();
    const url = window.location.href;
    const siteID = url.split("=")[1];

    var startDate = "";
    var endDate = "";

    console.log("Senix:props:",props.update);

    function getEvents(start,end) {
        const isoStart = start.toISOString().split("T")[0];
        const isoEnd = end.toISOString().split("T")[0];
        
        console.log("Senix:getEvents:URL:",url.split("="));
        console.log("Senix:getEvents:site id:",siteID);
        console.log("Senix:getEvents:start:",isoStart);
        console.log("Senix:getEvents:end:",isoEnd);

        const queryStr = {
            query: `query EventsByDate($id:ID!,$start:AWSDate!,$end:AWSDate!){
                getEventsByDate(id: $id, start: $start, end: $end) {
                    nextToken
                    events {
                        msg,
                        pk,
                        sk
                    }
                    site {
                        active,
                        name,
                        description,
                        thingName,
                        msg,
                        pk,
                        parameters,
                        lastUpdate
                    }
                }
            }`,
            variables: {
               id:siteID,
               start:isoStart,
               end:isoEnd
            }
        }

        fetch(asURL,{
            method:'POST',
            headers: {
                "Content-Type":"application/graphql",
                "x-api-key":asKey
            },
            body:JSON.stringify(queryStr)
        })
        .then(response => response.json())
        .then(resp => {

            console.log("resp:",resp.data.getEventsByDate);

            // Convert the params to JSON
            for (var i in resp.data.getEventsByDate.site.parameters) {
                resp.data.getEventsByDate.site.parameters[i] = JSON.parse(resp.data.getEventsByDate.site.parameters[i])
            }

            setTitle(resp.data.getEventsByDate.site.name);
            setSite(prevSite => {
                return resp.data.getEventsByDate.site;
            });
            console.log("Senix:Site:",resp.data.getEventsByDate.site);
            
            // Setup Event related components
            if (resp.data.getEventsByDate.events.length === 0) return;

            // Save events
            setEvents(resp.data.getEventsByDate.events);

            // Get last event
            var lastEvent = JSON.parse(resp.data.getEventsByDate.site.msg);
            lastUpdate = new Date(lastEvent.timestamp * 1000).toLocaleString();
        })
    }

    useEffect(() => {
        let s = new Date();
        let e = new Date();

        console.log("Senix:useEffect:start");
        s.setDate(e.getDate() - 7);
        e.setDate(e.getDate() + 1);
        getEvents(s,e);

        console.log("Senix:useEffect:end");
    },[props]);

    const activeStyle = {
        textAlign:'center',
        fontSize:16,
        bottom:5,
        left:5,
        color:theme.onPrimary
    }

    const appBarStyle = {
        backgroundColor: theme.primary,
        position:'fixed',
        top:0,
        width:'100%',
        padding:5,
        zIndex:100,
        boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.26)"
      };

    const wrapper = {
        width:'100%'
    }

    const backStyle = {
        position:'absolute',
        top:0,
        color:'white'
    }

    const dateStyle = {
        margin:10 
    }

    const gridStyle = {
        marginTop:100
    }

    const lastUpdateStyle = {
        textAlign:"center",
        color: theme.onPrimary,
    }

    const radioStyle = {
        marginBottom:20,
        color: "rgb(96, 96, 96)"
    }

    const radioGroupStyle = {
        margin:10
    }

    const titleStyle = {
        color:theme.onPrimary,
        textAlign:"center",
        overflow:"hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        fontSize:28,
        fontWeight:'bold',
      }

    const filledButtonStyle = {
        margin:"auto",
        marginTop:20,
        marginBottom:20
    }

    const filterIcon = {
        position:'absolute',
        right:5,
        top:0
    };

    const InfoIcon = {
        position:'absolute',
        right:50,
        top:0
    };

    const linkStyle = {
        fontSize:21,
        textDecoration: 'none',
        color:'rgb(32,32,32)'
    }

    function onFilterOpen(){
        setShadowState(true);
        setFilterState(true);
    }
    
    function onShadow() {
        setShadowState(false);
        setFilterState(false);
    }

    function onRadioChange(e) {
        startDate = new Date();
        endDate = new Date();

        startDate.setDate(endDate.getDate() - e.target.value);
        console.log("onRadioChange:",startDate,endDate);
    };

    function onStartDate(e) {
        startDate = new Date(e.target.value);
        console.log("onStartDate:",startDate);
    }

    function onEndDate(e) {
        endDate = new Date(e.target.value);
        console.log("onEndDate:",endDate);
    }

    function onOK(e) {
        endDate.setDate(endDate.getDate() + 1);
        
        getEvents(startDate,endDate);
        setShadowState(false);
        setFilterState(false);
    }

    function onCancel(e) {
        setShadowState(false);
        setFilterState(false);
    }

    return (
        <div className="senix" style={wrapper} onClick={props.onClick}>
            <Shadow visible = {shadowState} onClose={onShadow}/>
            <Drawer
                anchor = 'right'
                visible = {filterState} 
                title = "Filter"
            >
                <div style={radioGroupStyle} onChange={onRadioChange}>
                    <RadioButton style={radioStyle} value="1" name="filter" label="Today" />
                    <RadioButton style={radioStyle} value="2" name="filter" label="Last 2 Days" />
                    <RadioButton style={radioStyle} value="7" name="filter" label="Last 7 Days" />
                    <RadioButton style={radioStyle} value="14" name="filter" label="Last 14 Days" />
                    <RadioButton style={radioStyle} value="30" name="filter" label="Last 30 Days" />
                </div>
                <DateField style={dateStyle} onBlur={onStartDate}></DateField>
                <DateField style={dateStyle} onBlur={onEndDate}></DateField>
                <FilledButton style={filledButtonStyle} onSelect={onOK}>Ok</FilledButton>
                <FilledButton style={filledButtonStyle} onSelect={onCancel}>Cancel</FilledButton>
            </Drawer>

            <div style={appBarStyle}>
                <div id="title" style={titleStyle}>{title}</div>
                <div style={lastUpdateStyle}>Updated: {lastUpdate.toLocaleString()}</div>
                <div style={activeStyle} id="active">Status: {site.active ? 'Online' : 'Offline'}</div>

                <BackButton style={backStyle} onSelect={() => navigate(-1)}></BackButton>
                <Link style={linkStyle} to={'/Info' + '?id=' + site.pk} >
                    <InfoIconButton style={InfoIcon} />
                </Link>                
                <FilterIconButton style={filterIcon} onSelect={onFilterOpen}/>

            </div>
            <Grid className="cards" style={gridStyle}>
                {console.log(site.parameters)}
                {
                    // if(site.parameters != undefined) {
                        site.parameters.map((param,i) => {
                            {console.log("Card:",param.type)}
                            return (
                                <GridItem key={i}>
                                    {Cards(param.type,site)}
                                </GridItem>
                            )
                        })
                    // }
                }
            </Grid>
            {
                site.parameters.map((param,i) => {
                    return (
                        Graphs(param,site,events)
                    )
                })
            }
        </div>
    )
};