import React from "react";
// import AirTemp from "./AirTemp";
// import Current from "./Current";
// import Signal from "./Signal";
// import SignalQuality from "./SignalQuality";
import Stage from "./Stage";
// import Voltage from "./Voltage";

const Tables = {
  // airTemp:AirTemp,
  // current: Current,
  // signal:Signal,
  // signalQuality:SignalQuality,
  stage: Stage,
  // voltage: Voltage,
};

export default (type,sites) => {
  if (typeof Tables[type] !== "undefined") {
    return React.createElement(Tables[type], {
      sites:sites
    });
  }
  return React.createElement(
    () => <div style={{width:300}}>The {type} table has not been created.</div>
  );
};