import React from 'react';
import {useContext } from "react";
import StageIcon from '../../../images/Stage.svg'
import "../../style.css";
import { ThemeContext } from '../../../App.js';

export default function Stage(props) {
    const theme = useContext(ThemeContext);

    // console.log("StageCard:site:",props.site);
    console.log("StageCard:readings:",props.reading);

    const icon = {
        position:'absolute',
        top:5,
        left:5,
        width:16,
        height:16,
        color:'rgb(25, 145, 220)'
    }

    const nameStyle = {
        marginLeft:20
    }

    // Setup
    var tStatus = "Normal";
    var reading;
    var levelMM;
    // var reading = props.reading;
    var message = props.site.message;

    console.log("StageCard:message:",message);

    if(message != null) {

        if(message.maxbotix != null) {
            levelMM = (props.reading.elevation - message.maxbotix.mm);
            reading = (levelMM / 304.8).toFixed(2);
        } else if(message.senix != null) {
            levelMM = (props.reading.elevation - message.senix.mm);
            reading = (levelMM / 304.8).toFixed(2);
        } else if(message.csi != null) {
            levelMM = (message.csi.basic * 304.8);
            reading = (props.elevation.elevation - message.csi.basic.ft).toFixed(2);
        } else {
            levelMM = 0;
            reading = 0;
        }
        
        props.reading.thresholds.forEach( threshold => {
            if(levelMM > threshold.value) {
                tStatus = threshold.name;
            }
        })

        console.log("StageCard:reading:",props.reading);
        console.log("StageCard:reading:mm:",levelMM);
        console.log("StageCard:reading:feet:",reading);
        console.log(tStatus);

    } else {
        console.log("StageCard:No Message");
        levelMM = 0;
        reading = 0;
        tStatus = "No Message";

        props.reading.thresholds.forEach( threshold => {
            if(levelMM > threshold.value) {
                tStatus = threshold.name;
            }
        })

        console.log("StageCard:param:",props.reading);
        console.log("StageCard:reading:mm:",levelMM);
        console.log("StageCard:reading:feet:",reading);
        console.log(tStatus);
        // param = null
    }

    return (
        <div className="card stage">
            <img src={StageIcon} style={icon} alt="Stage Icon"/>
            <div className="name" style={nameStyle}>{props.reading.name}</div>
            <div className="wrapper">
                <div className="reading">{reading}</div>
                <div className="unit">ft.</div>
            </div>
        </div>
    )
}