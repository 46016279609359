import React from 'react';
import { useState, useContext } from "react";
import { ThemeContext } from '../../App.js';
import "../style.css";

import Chart from "react-apexcharts";

export default function PrecipType(props) {
  const theme = useContext(ThemeContext);

  var options = {
    chart: {
      id: "precipTypeChart", 
      redrawOnWindowResize: true, 
      animations:{enabled:true},
      toolbar:{
        show:true,
        tools:{
          download:false
        },
        export:{
          csv:{
            filename:"precipIntensity",
            headerCategory: 'Date',
            headerValue: 'stage',
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString()
            }
          }
        }
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        barHeight: '50%',
        horizontal: true,
        distributed: true,
        dataLabels: {
          hideOverflowingLabels: false
        }
      }
    },
    title: {
      text:"", 
      align: 'center',
      style:{fontSize:'21px'}
    },
    markers: {
      hover: {size: 10}
    },
    dataLabels: {
      enabled:false
    },
    yaxis: {
      reversed:true
    },
    colors:['#0080FF'],
    tooltip: {x:{format:"MM-dd HH:mm:ss"}},
    xaxis: {type: 'datetime',labels: {format: 'MM-dd HH:mm',datetimeUTC:false}},
    zoom:{enabled:true,type:"xy",autoScaleYaxis:true},
  }

  {console.log("PrecipTypeGraph:site:",props.site)}
  {console.log("PrecipTypeGraph:reading:",props.reading)}
  {console.log("PrecipTypeGraph:messages:",props.messages)}

  options.title.text = props.reading.name

  // Add precip types
  var series = [];
  var sObj = {};
  
  sObj.data = []
  series.push(sObj);

  props.reading.thresholds.forEach(reading => {
    var dataObj = {};

    dataObj.x = reading.name;
    dataObj.y = [];
    dataObj.fillColor = reading.color;

    props.messages.forEach(message => {
      var dt = message.msg.timestamp * 1000;

      if(message.msg.lufft.precip.type === reading.value) {
        dataObj.y.push(dt)
      }
    })

    console.log("PrecipTypeGraph:len:",dataObj.y.length)

    if(dataObj.y.length == 0) {
      dataObj.y.push(new Date().getTime());
      dataObj.y.push(new Date().getTime());
    }

    series[0].data.push(dataObj);
  })

  console.log("Series:",series);

  return (
    <Chart 
      className="chart"
      type="rangeBar"
      width="100%"
      height="300px"
      options={options}
      series={series}
    >
    </Chart>
  )
}

