import React from 'react';
import { useState, useContext } from "react";
import { ThemeContext } from '../App';

export default function Grid(props) {
    const theme = useContext(ThemeContext);
    const gridStyle = {
        display:"grid",
        rowGap:10,
        justifyContent: 'space-evenly'
    }

    return (
        <div className={props.className} style={Object.assign(gridStyle,props.style)} >
            {props.children}
        </div>
    )
}

