import React from 'react';
import { useState, useContext, useEffect, useRef } from "react";
import { Link, useSearchParams } from "react-router-dom";

import AddressLabel from '../components/AddressLabel.js';
import AppBar from '../components/AppBar.js';
import CommsLabel from '../components/CommsLabel.js';
import DateField from '../components/DateField.js';
import Dialog from '../components/Dialog.js';
import Drawer from '../components/Drawer.js';
import FilledButton from '../components/buttons/FilledButton.js';
import FilterIconButton from '../components/buttons/FilterIconButton.js';
import Grid from '../components/Grid.js';
import GridItem from '../components/GridItem.js';
import InfoIconButton from '../components/buttons/InfoIconButton.js';
import Label from '../components/Label.js';
import LocationLabel from '../components/LocationLabel.js';
import RadioButton from '../components/RadioButton.js';
import SensorsLabel from '../components/SensorsLabel.js';
import Shadow from '../components/Shadow.js';
import StatusCard from '../components/cards/StatusCard.js';

import StageChart from '../components/StageChart.js';

import Chart from "react-apexcharts";
import ApexCharts from 'apexcharts'

import { AuthContext } from '../App';
import { ThemeContext } from '../App';
// import { autocompleteClasses } from '@mui/material';
// import { setSelectionRange } from '@testing-library/user-event/dist/utils/index.js';

var stageOptions = {
    chart: {id: "stageChart", redrawOnWindowResize: true, animations:{enabled:true}},
    title: {text:"Water Level", align: 'center',style:{fontSize:'21px'}},
    markers: {hover: {size: 10}},
    dataLabels: {enabled:false},
    colors:['#0080FF'],
    tooltip: {x:{format:"MM-dd hh:mm"}},
    xaxis: {type: 'datetime',labels: {format: 'MM-dd hh:mm'}},
    yaxis: {
        labels: {
            formatter: function (value) {
              return value.toFixed(1) + " ft.";
            }
        },
        min:0,
        max:0
    },
    zoom:{
        enabled:true,
        type:"xy",
        autoScaleYaxis:true
    }
}

const voltOptions = {
    chart: {id: "voltChart",
        legend: {
            position: 'top'
        }
    },
    title: {
        text:"Voltage",
        align: 'center',
        style:{
            fontSize:'21px'
        }
    },
    markers: {
        // size:5,
        hover: {size: 10}
    },
    dataLabels: {enabled:false},
    colors:['#FF0000'],
    tooltip: {
        x:{
            format:"MM-dd hh:mm"
        }
    },
    xaxis: {
        type: 'datetime',
        labels: {
            format: 'MM-dd hh:mm',
        }
    },
    yaxis: {
        labels: {
            formatter: function (value) {
              return value.toFixed(1) + " v";
            }
        },
        decimalsInFloat:1,
        min:0,
        max:4.3
    }
}

const signalOptions = {
    chart: {
        id: "signalChart",
        legend: {
            position: 'top'
        }
    },
    title: {
        text:"Signal",
        align: 'center',
        style:{
            fontSize:'21px'
        }
    },
    markers: {
        // size:5,
        hover: {size: 10}
    },
    dataLabels: {
        enabled:false
    },
    colors:['#8A2BE2'],
    tooltip: {
        x:{
            format:"MM-dd hh:mm"
        }
    },
    xaxis: {
        type: 'datetime',
        labels: {
            format: 'MM-dd hh:mm',
        }
    },
    yaxis: {
        labels: {
            formatter: function (value) {
              return value.toFixed(1) + " dB";
            }
        },
        reversed:true,
        min:-120,
        max:-20
    }
}

const initSite = {
    active:false,
    name:"",
    description:"",
    sensors:[
        {
            make:"",
            model:"",
            value:0
        }
    ],
    location:{
        latitude:0,
        longitude:0
    },
    address:{
        address_1:"",
        address_2:"",
        city:"",
        state:"",
        zip:""
    }
}

const statInit = {value:0,param:{max:0,min:0,thresholds:[]}}

export default function Maxbotix(props) {
    const auths = useContext(AuthContext);
    const theme = useContext(ThemeContext);
    const [shadowState, setShadowState] = useState(false);
    const [filterState, setFilterState] = useState(false);
    const [dialogVisible, setDialogVisible] = useState(false);

    const [lastEvent, setLastEvent] = useState({});
    const [site, setSite] = useState(initSite);
    const [stage, setStage] = useState(statInit);
    const [voltage, setVoltage] = useState(statInit);
    const [signal, setSignal] = useState(statInit);

    // const [update, setUpdate] = useState(Math.random());
    const [comms, setComms] = useState({})
    const [levelSeries, setLevelSeries] = useState([{name:"Level",data:[0,0]}]);
    const [voltSeries, setVoltSeries] = useState([{name:"Voltage",data:[0,0]}]);
    const [signalSeries, setSignalSeries] = useState([{name:"Signal",data:[0,0]}]);
    // const [level, setLevel] = useState(0.00);    

    const [queryParameters] = useSearchParams();
    const siteID = queryParameters.get("id");

    var startDate = "";
    var endDate = "";

    let qStr = `query GetEventsByDate {
        getEventsByDate(end: "<end>", site: "<site>", start: "<start>") {
          nextToken
          events {msg,pk,sk}
          site {name,comms,description,sensors{input,make,model,slot},parameters,location{latitude,longitude},address{address_1,address_2,city,state,zip}}
        }
    }`

    function getEvents(start,end) {
        const isoStart = start.toISOString().split("T")[0];
        const isoEnd = end.toISOString().split("T")[0];
        console.log("Maxbotix:getEvents:start:",isoStart);
        console.log("Maxbotix:getEvents:end:",isoEnd);

        qStr = qStr.replace("<site>",siteID);
        qStr = qStr.replace("<start>",isoStart);
        qStr = qStr.replace("<end>",isoEnd);

        const query = {query: qStr,variables: {} }

        fetch('https://ebkrbuiepncdzkjpgclwpifxxi.appsync-api.us-east-1.amazonaws.com/graphql',{
            method:'POST',
            headers: {
                "Content-Type":"application/graphql",
                "x-api-key":"da2-6jq5ueouyjdrnnhkk6heqzd7ji"
            },
            body:JSON.stringify(query)
        })
        .then(response => response.json())
        .then(resp => {
            if (resp.data.getEventsByDate.events.length === 0) return;

            // Convert the params to JSON
            for (var i in resp.data.getEventsByDate.site.parameters) {
                resp.data.getEventsByDate.site.parameters[i] = JSON.parse(resp.data.getEventsByDate.site.parameters[i])
            }

            // Convert Comms to JSON
            resp.data.getEventsByDate.site.comms = JSON.parse(resp.data.getEventsByDate.site.comms);

            setSite(resp.data.getEventsByDate.site);
            console.log(resp.data.getEventsByDate.site);

            // Get last event
            var lastEvent = JSON.parse(resp.data.getEventsByDate.events[0].msg);
            setLastEvent(lastEvent);

            // Setup Stage
            var stageParam = resp.data.getEventsByDate.site.parameters.find(p => { return p.type == "stage"});
            setStage({
                param:stageParam,
                value:(stageParam.elevation - lastEvent.maxbotix.mm)
            })
            console.log("Stage:",stage);

            // Setup Voltage
            setVoltage({
                param:resp.data.getEventsByDate.site.parameters.find(p => { return p.type == "voltage"}),
                value:lastEvent.ina219.voltage
            })
            console.log("Maxbotix:voltage:",voltage);

            // Setup Signal
            setSignal({
                param:resp.data.getEventsByDate.site.parameters.find(p => { return p.type == "signal"}),
                value:lastEvent.lte.rssi
            })
            console.log("Signal:",signal);

            // Load the graph series
            var stageSeries = [{name:'stage',data:[]}];
            var voltSeries = [{name:'voltage',data:[]}];
            var signalSeries = [{name:'signal',data:[]}];

            resp.data.getEventsByDate.events.forEach(event => {
                var evt = JSON.parse(event.msg);
                var dt = event.sk.replace("EVENT#","")
                var dist = ((stageParam.elevation - evt.maxbotix.mm) / 304.8).toFixed(2);

                stageSeries[0].data.push({x:dt,y:dist});
                voltSeries[0].data.push({x:dt, y:(evt.ina219.voltage).toFixed(2)});

                if("lte" in evt) {
                    signalSeries[0].data.push({x:dt,y:(evt.lte.rssi).toFixed(2)});
                }

                if("wifi" in evt) {
                    signalSeries[0].data.push({x:dt,y:(evt.wifi.rssi).toFixed(2)});
                }
            })

            // Setup Stage Graph
            stageOptions.yaxis.max = (stageParam.max / 304.8);
            stageOptions.yaxis.min = (stageParam.min / 304.8);
            console.log("Maxbotix:Stage options:",stageOptions);

            const stageChart = ApexCharts.getChartByID('stageChart');
            stageChart.updateOptions(stageOptions);
            stageChart.updateSeries(stageSeries);
            stageParam.thresholds.forEach(threshold => {
                stageChart.addYaxisAnnotation(
                    {
                        y:(threshold.value / 304.8),
                        y2:(threshold.value / 304.8) + 0.025,
                        borderColor:threshold.color,
                        fillColor:threshold.color,
                        strokeDashArray:0,
                        label: {
                            text: threshold.name,
                            textAnchor:'start',
                            position:'left',
                            offsetX:10
                        },
                    },
                    false
                );
            })

            // Setup Voltage Graph
            voltOptions.yaxis.max = voltage.param.max;
            voltOptions.yaxis.min = voltage.param.min;

            console.log("volt:",voltOptions);
            console.log(voltage);

            const voltChart = ApexCharts.getChartByID('voltChart');
            voltChart.updateOptions(voltOptions);
            voltChart.updateSeries(voltSeries);

            // Setup Signal Graph
            const sigChart = ApexCharts.getChartByID('signalChart');
            sigChart.updateOptions(signalOptions);
            sigChart.updateSeries(signalSeries);
        })
    }

    useEffect(() => {
        let s = new Date();
        let e = new Date();

        console.log("Maxbotix useEffect:start");
        s.setDate(e.getDate() - 7);
        getEvents(s,e);
        console.log("Maxbotix useEffect:end");
    },[props]);

    const wrapper = {
        width:'100%'
    }

    const dateStyle = {
        margin:10 
    }

    const dialogStyle = {
        zIndex:100
    }

    const dialogGridStyle = {
        justifyContent:"start",
        gridTemplateColumns: 'auto auto'
    }

    const gridStyle = {
        marginTop:40,
        // gridTemplateColumns: 'auto auto auto auto'
    }

    const levelStyle = {
        marginTop:40,
        backgroundColor:'rgb(255,255,255)',
        boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.26)"
    }

    const radioStyle = {
        marginBottom:20,
        color: "rgb(96, 96, 96)"
    }

    const radioGroupStyle = {
        margin:10
    }

    const filledButtonStyle = {
        margin:"auto",
        marginTop:20,
        marginBottom:20
    }

    const filterIcon = {
        position:'absolute',
        right:5,
        top:5
    };

    const InfoIcon = {
        position:'absolute',
        right:50,
        top:5
    };

    const labelStyle = {
        width:300,
        marginTop:20
    }

    function onFilterOpen(){
        setShadowState(true);
        setFilterState(true);
    }
    
    function onShadow() {
        setShadowState(false);
        setFilterState(false);
        setDialogVisible(false);
    }

    function onInfoOpen() {
        setShadowState(true);
        setDialogVisible(true);
    }

    function onRadioChange(e) {
        startDate = new Date();
        endDate = new Date();

        startDate.setDate(endDate.getDate() - e.target.value);
        console.log(startDate,endDate);
    };

    function onStartDate(e) {
        startDate = new Date(e.target.value);
        console.log("Start Date:",startDate);
    }

    function onEndDate(e) {
        endDate = new Date(e.target.value);
        console.log("End Date:",endDate);
    }

    function onOK(e) {
        getEvents(startDate,endDate);
        setShadowState(false);
        setFilterState(false);
        setDialogVisible(false);
    }

    function onCancel(e) {
        setShadowState(false);
        setFilterState(false);
        setDialogVisible(false);
    }

    function onDialogClose(e) {
        setShadowState(false);
        setFilterState(false);
        setDialogVisible(false);
    }

    return (
        <div className="maxbotix" style={wrapper} onClick={props.onClick}>
            {console.log("stage:",stage)}
            {console.log("voltage:",voltage)}
            <Dialog 
                style={dialogStyle} 
                visible={dialogVisible}
                title="Site Overview"
                onClose = {onDialogClose}
            >
                <Grid style={dialogGridStyle}>
                    <GridItem>
                        <Label title="Name">{site.name}</Label>
                        <Label title="Description" style={labelStyle}>{site.description}</Label>
                        <LocationLabel location={site.location} />
                        <AddressLabel address={site.address}/>
                        <SensorsLabel sensors={site.sensors}></SensorsLabel>
                        <CommsLabel comms={site.comms}></CommsLabel>
                    </GridItem>
                    <GridItem>
                        <Label title="Stage"></Label>
                        <span style={{marginTop:5,fontSize:14, color:"rgb(92,92,92"}}>Min:</span>
                        <span style={{marginLeft:5,fontSize:14, fontWeight:550}}>{(stage.param.min / 304.8).toFixed(2)}</span>
                        <span style={{marginLeft:5,fontSize:14, fontWeight:550}}>ft.</span>

                        <span style={{marginTop:5,marginLeft:10,fontSize:14, color:"rgb(92,92,92"}}>Max:</span>
                        <span style={{marginLeft:5,fontSize:14, fontWeight:550}}>{(stage.param.max / 304.8).toFixed(2)}</span>
                        <span style={{marginLeft:5,fontSize:14, fontWeight:550}}>ft.</span>

                        <div style={{marginTop:5,marginBottom:5,fontSize:14,color:"rgb(92,92,92"}}>Thresholds:</div>
                        {
                            stage.param.thresholds.map((threshold,i) => {
                                var str = (threshold.value / 304.8).toFixed(2) + " ft. - " + threshold.name;
                                return (
                                    <div key={i} style={{fontSize:14,fontWeight:550}}>{str}</div>
                                )
                            })
                        }

                        <Label title="Voltage" style={{marginTop:20}}></Label>
                        <span style={{marginTop:5,fontSize:14, color:"rgb(92,92,92"}}>Min:</span>
                        <span style={{marginLeft:5,fontSize:14, fontWeight:550}}>{voltage.param.min}</span>
                        <span style={{marginLeft:5,fontSize:14, fontWeight:550}}>v</span>

                        <span style={{marginTop:5,marginLeft:10,fontSize:14, color:"rgb(92,92,92"}}>Max:</span>
                        <span style={{marginLeft:5,fontSize:14, fontWeight:550}}>{voltage.param.max}</span>
                        <span style={{marginLeft:5,fontSize:14, fontWeight:550}}>v</span>

                        <Label title="Signal" style={{marginTop:20}}></Label>
                        <span style={{marginTop:5,fontSize:14, color:"rgb(92,92,92"}}>Min:</span>
                        <span style={{marginLeft:5,fontSize:14, fontWeight:550}}>{signal.param.min}</span>
                        <span style={{marginLeft:5,fontSize:14, fontWeight:550}}>dB</span>

                        <span style={{marginTop:5,marginLeft:10,fontSize:14, color:"rgb(92,92,92"}}>Max:</span>
                        <span style={{marginLeft:5,fontSize:14, fontWeight:550}}>{signal.param.max}</span>
                        <span style={{marginLeft:5,fontSize:14, fontWeight:550}}>dB</span>
                    
                    </GridItem>
                </Grid>
            </Dialog>
            <Shadow visible = {shadowState} onClose={onShadow}/>
            <Drawer 
                anchor = 'right'
                visible = {filterState} 
                title = "Filter"
            >
                <div style={radioGroupStyle} onChange={onRadioChange}>
                    <RadioButton style={radioStyle} value="1" name="filter" label="Today" />
                    <RadioButton style={radioStyle} value="2" name="filter" label="Last 2 Days" />
                    <RadioButton style={radioStyle} value="7" name="filter" label="Last 7 Days" />
                    <RadioButton style={radioStyle} value="14" name="filter" label="Last 14 Days" />
                    <RadioButton style={radioStyle} value="30" name="filter" label="Last 30 Days" />
                </div>
                <DateField style={dateStyle} onBlur={onStartDate}></DateField>
                <DateField style={dateStyle} onBlur={onEndDate}></DateField>
                <FilledButton style={filledButtonStyle} onSelect={onOK}>Ok</FilledButton>
                <FilledButton style={filledButtonStyle} onSelect={onCancel}>Cancel</FilledButton>
            </Drawer>

            <AppBar title = {site.name} >
                {/* <Link style={linkStyle} to="/Sites">
                    <BackButton style={backStyle}></BackButton>
                </Link> */}
                <InfoIconButton style={InfoIcon} onSelect={onInfoOpen}/>
                <FilterIconButton style={filterIcon} onSelect={onFilterOpen}/>
            </AppBar>
            <div style={{textAlign:"center",marginTop:5}}>{new Date(lastEvent.timestamp * 1000).toLocaleString()}</div>
            <Grid className="cards" style={gridStyle}>
                <GridItem>
                    <StatusCard 
                        name="Water Level"
                        param = {stage.param}
                        value = {stage.value}
                        unit = 'ft.'
                        status=""
                    />
                </GridItem>
                <GridItem>
                    <StatusCard 
                        name="Voltage"
                        param = {voltage.param}
                        value = {voltage.value}
                        unit = 'v'
                        status=""
                    />  
                </GridItem>
                <GridItem>
                    <StatusCard 
                        name="Signal"
                        param = {signal.param}
                        value={signal.value}
                        unit = 'dB'
                        status=""
                    />  
                </GridItem>
            </Grid>
            <Chart
                style={levelStyle}
                options={stageOptions}
                series={levelSeries}
                type="area"
                width="100%"
                height="400px"
            />
            <Chart
                style={levelStyle}
                options={voltOptions}
                series={voltSeries}
                type="area"
                width="100%"
                height="400px"
            />
            <Chart
                style={levelStyle}
                options={signalOptions}
                series={signalSeries}
                type="area"
                width="100%"
                height="400px"
            />
        </div>
    )
};