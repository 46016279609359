import React from 'react';
import { useState, useContext } from "react";

import WindDirIcon from '../../../images/WindDir.svg'

import { ThemeContext } from '../../../App.js';
import "../../style.css";

export default function WindDir(props) {
    const theme = useContext(ThemeContext);

    const icon = {
        position:'absolute',
        top:5,
        left:5,
        width:16,
        height:16,
        color:'rgb(25, 145, 220)'
    }

    const nameStyle = {
        marginLeft:20
    }

    const valueStyle = {
        display:'inline',
        fontSize:32,
        fontWeight:"bold",
        marginTop:5
    }

    const valueWrapper = {
        position:'relative',
        width:'100%',
        textAlign:'center',
        marginTop:10
    }

    const unitStyle = {
        display:'inline',
        fontSize:16,
        marginLeft:5
    }

    function findParam(name) {
        var params;
        console.log("WindDir:findParam:",name);

        for (var i in props.site.parameters) {
            if(props.site.parameters[i].type == name) {
                return props.site.parameters[i];
            }
        }
        console.log("WindDir:unknown type:",name);
        return null;
    }

    // Setup
    var tStatus = "Normal";
    // var param;
    var message = props.site.message;
    var value = 0;

    console.log("WindDir:site:",props.site);
    console.log("WindDir:reading:",props.reading);

    if(message != null) {
        if(message.lufft !== null) {
            if(message.lufft.windDir != null) {
                value = (message.lufft.windDir).toFixed(1);
            }
        }
        
        props.reading.thresholds.forEach( threshold => {
            if(value > threshold.value) {
                tStatus = threshold.name;
            }
        })

        console.log(tStatus);

    } else {
        console.log("WindDir:No Message");
        value = 0;
        tStatus = "No Message";
    }

    return (
        <div className="card windDir">
            <img src={WindDirIcon} style={icon} alt="Wind Direction Icon"/>
            <div className="name" style={nameStyle}>{props.reading.name}</div>
            <div className="wrapper">
                <div className="reading">{value}</div>
                <div style={unitStyle}>deg.</div>
            </div>
        </div>
    )
}