import React from 'react';
import { useState } from "react";
import '../style.css';

export default function MenuItem(props) {
  const [hover, setHover] = useState(false);
  const [enabled, setEnabled] = useState(false);

//   const enabledStyle = {
//       display:"block",
//       fontSize:21,
//       height:40,
//       lineHeight:'40px',
//       paddingLeft:10,
//       color:'rgb(0,0,0)',
//       backgroundColor: hover ? 'rgba(192,192,192,0.3)' : 'transparent',
//   }

//   const disabledStyle = {
//       display:"block",
//       fontSize:21,
//       lineHeight:'40px',
//       height:40,
//       paddingLeft:10,
//       backgroundColor:'rgba(192,192,192,0.3)'
//   }

  const linkStyle = {
      fontSize:21,
      lineHeight:'40px',
      textDecoration: 'none',
      color:'rgb(32,32,32)'
  }

  const mouseEnter = () => {
      setHover(true);
  };

  const mouseLeave = () => {
      setHover(false);
  };

  function menuClick(e) {
      props.setTitle(props.children)
      props.setMenu()
  }

  if(props.enabled) {
      return(
          <div 
            className={"menu item"}
            // style={enabledStyle} 
            onClick={props.onSelect}
            onMouseEnter={mouseEnter}
            onMouseLeave={mouseLeave}
          >
            {props.children}
          </div>
      )
  } else {
      return(
          <div 
            className={"menu item disabled"}
            // style={disabledStyle} 
          >
              {props.children}
          </div> 
      )
  }
}