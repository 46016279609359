import React from 'react';
import { useState, useContext, useEffect, useRef } from "react";
import { Link, useSearchParams, useNavigate } from "react-router-dom";

import AddressLabel from '../components/AddressLabel.js';
import AppBar from '../components/AppBar.js';
import BackButton from '../components/buttons/BackButton.js';
import CommsLabel from '../components/CommsLabel.js';
import DateField from '../components/DateField.js';
import Dialog from '../components/Dialog.js';
import Drawer from '../components/Drawer.js';
import FilledButton from '../components/buttons/FilledButton.js';
import FilterIconButton from '../components/buttons/FilterIconButton.js';
import Grid from '../components/Grid.js';
import GridItem from '../components/GridItem.js';
import InfoIconButton from '../components/buttons/InfoIconButton.js';
import Label from '../components/Label.js';
import LocationLabel from '../components/LocationLabel.js';
import PrecipTypeCard from '../components/cards/PrecipType.js';
import RadioButton from '../components/RadioButton.js';
import SensorsLabel from '../components/SensorsLabel.js';
import Shadow from '../components/Shadow.js';
import StatusCard from '../components/cards/StatusCard.js';

import Chart from "react-apexcharts";
import ApexCharts from 'apexcharts'

import { AuthContext } from '../App';
import { ThemeContext } from '../App';

var absAirPressOptions = {
    chart: {id: "absAirPressChart",legend: {position: 'top'}},
    title: {text:"Air Pressure",align: 'center',style:{fontSize:'21px'}},
    markers: {hover: {size: 10}},
    dataLabels: {enabled:false},
    colors:['#0080FF'],
    tooltip: {x:{format:"MM-dd HH:mm:ss"},
        y:{formatter:function (value) {
            return value.toFixed(1) + " hPa";
        }}
    },
    xaxis: {type: 'datetime',labels: {format: 'MM-dd HH:mm',datetimeUTC:false}},
    yaxis: {
        labels: {
            formatter: function (value) {
              return value.toFixed(0) + " hPa";
            }
        },
        decimalsInFloat:1,
        min:0,max:4.3
    },
    stroke:{
        width:2
    }

}

var accumOptions = {
    chart: {id: "accumChart",legend: {position: 'top'}},
    title: {text:"Precip. Accumulation",align: 'center',style:{fontSize:'21px'}},
    markers: {hover: {size: 10}},
    dataLabels: {enabled:false},
    colors:['#0080FF'],
    tooltip: {x:{format:"MM-dd H:mm:ss"}},
    xaxis: {type: 'datetime',labels: {format: 'MM-dd HH:mm',datetimeUTC:false}},
    yaxis: {labels: {
            formatter: function (value) {
              return value.toFixed(1) + " in";
            }
        },
        decimalsInFloat:1,min:0,max:4.3
    },
    stroke:{
        width:2
    }
}

var airTempOptions = {
    chart: {id: "airTempChart", redrawOnWindowResize: true, animations:{enabled:true}},
    title: {text:"Air Temperature", align: 'center',style:{fontSize:'21px'}},
    markers: {hover: {size: 10}},
    dataLabels: {enabled:false},
    colors:['#0080FF'],
    tooltip: {x:{format:"MM-dd HH:mm:ss"}},
    xaxis: {type: 'datetime',labels: {format: 'MM-dd HH:mm',datetimeUTC:false}},
    yaxis: {labels: {
            formatter: function (value) {
              return value.toFixed(1) + " F";
            }
        },
        min:0,max:0
    },
    zoom:{enabled:true,type:"xy",autoScaleYaxis:true},
    stroke:{
        width:2
    }
}

var humidityOptions = {
    chart: {id: "humidityChart",legend: {position: 'top'}},
    title: {text:"Relative Humidity",align: 'center',style:{fontSize:'21px'}},
    markers: {hover: {size: 10}},
    dataLabels: {enabled:false},
    colors:['#0080FF'],
    tooltip: {x:{format:"MM-dd HH:mm:ss"}},
    xaxis: {type: 'datetime',labels: {format: 'MM-dd HH:mm',datetimeUTC:false}},
    yaxis: {
        labels: {
            formatter: function (value) {
              return value.toFixed(1) + " %";
            }
        },
        reversed:false,
        min:0,max:0
    },
    stroke:{
        width:2
    }
}

var intenseOptions = {
    chart: {id: "intenseChart",legend: {position: 'top'}},
    title: {text:"Precip. Intensity",align: 'center',style:{fontSize:'21px'}},
    markers: {hover: {size: 10}},
    dataLabels: {enabled:false},
    colors:['#0080FF'],
    tooltip: {x:{format:"MM-dd HH:mm:ss"}},
    xaxis: {type: 'datetime',labels: {format: 'MM-dd HH:mm',datetimeUTC:false}},
    yaxis: {labels: {
            formatter: function (value) {
              return value.toFixed(1) + " in/hr";
            }
        },
        decimalsInFloat:1,min:0,max:4.3
    },
    stroke:{
        width:2
    }
}

var signalOptions = {
    chart: {id: "sigChart",legend: {position: 'top'}},
    title: {text:"Signal Strength",align: 'center',style:{fontSize:'21px'}},
    markers: {hover: {size: 10}},
    dataLabels: {enabled:false},
    colors:['#7851A9'],
    tooltip: {x:{format:"MM-dd HH:mm:ss"}},
    xaxis: {type: 'datetime',labels: {format: 'MM-dd HH:mm',datetimeUTC:false}},
    yaxis: {
        labels: {
            formatter: function (value) {
              return value.toFixed(1) + " dB";
            }
        },
        reversed:true,
        min:-120,max:-20
    },
    stroke:{
        width:2
    },
    fill:{
        colors:['#C3B1E1'],
        gradient:{
            shade:"light",
            opacityFrom: 0.5,
            opacityTo: 0.5,
        }
    }
}

var voltOptions = {
    chart: {id: "voltChart",legend: {position: 'top'}},
    title: {text:"Voltage",align: 'center',style:{fontSize:'21px'}},
    markers: {hover: {size: 10}},
    dataLabels: {enabled:false},
    colors:['#7851A9'],
    tooltip: {x:{format:"MM-dd HH:mm:ss"}},
    xaxis: {type: 'datetime',labels: {format: 'MM-dd HH:mm',datetimeUTC:false}},
    yaxis: {
        labels: {
            formatter: function (value) {
              return value.toFixed(1) + " v";
            }
        },
        reversed:false,
        min:0,max:0
    },
    stroke:{
        width:2
    },
    fill:{
        colors:['#C3B1E1'],
        gradient:{
            shade:"light",
            opacityFrom: 0.5,
            opacityTo: 0.5,
        }
    }
}

var windSpeedOptions = {
    chart: {id: "windChart", redrawOnWindowResize: true, animations:{enabled:true}},
    title: {text:"Wind Speed", align: 'center',style:{fontSize:'21px'}},
    markers: {hover: {size: 10}},
    dataLabels: {enabled:false},
    colors:['#0080FF'],
    tooltip: {x:{format:"MM-dd HH:mm:ss"}},
    xaxis: {type: 'datetime',labels: {format: 'MM-dd HH:mm',datetimeUTC:false}},
    yaxis: {labels: {
            formatter: function (value) {
              return value.toFixed(1) + " mph";
            }
        },
        min:0,max:0
    },
    zoom:{enabled:true,type:"xy",autoScaleYaxis:true},
    stroke:{
        width:2
    }
}

const initEvent = {
    lufft:{
        precip:{
            abs:0,
            intensity:0,
            type:0
        }
    }
}

const initSite = {
    active:false,
    name:"",
    description:"",
    sensors:[
        {
            make:"",
            model:"",
            value:0
        }
    ],
    location:{
        latitude:0,
        longitude:0
    },
    address:{
        address_1:"",
        address_2:"",
        city:"",
        state:"",
        zip:""
    }
}

var voltParam = {max:0,min:0,thresholds:[]};
var voltValue = 0;

var signalParam = {max:0,min:0,thresholds:[]};
var signalValue = -0;

var accumParam = {max:0,min:0,thresholds:[]};
var accumValue = 0;

var humidityParam = {max:100,min:0,thresholds:[]};
var humidityValue = 0;

var intenseParam = {max:0,min:0,thresholds:[]};
var intenseValue = 0;

var absAirPressParam = {max:0,min:0,thresholds:[]};
var absAirPressValue = 0;

var airTempParam = {max:0,min:0,thresholds:[]};
var airTempValue = 0;

var windDirParam = {max:0,min:0,thresholds:[]};
var windDirValue = 0;

var windSpeedParam = {max:0,min:0,thresholds:[]};
var windSpeedValue = 0;


var absAirPressSeries = [{name:'Air Pressure',data:[]}];
var accumSeries = [{name:'Accumulation',data:[]}];
var airTempSeries = [{name:'Air Temp.',data:[]}];
var humiditySeries = [{name:'Humidity',data:[]}];
var intenseSeries = [{name:'Intensity',data:[]}];
var sigSeries = [{name:'signal',data:[]}];
var voltSeries = [{name:'voltage',data:[]}];
var windDirSeries = [{name:'wind',data:[]}];
var windSpeedSeries = [{name:'wind',data:[]}];

var precipType = 0;
var lastUpdate = "-/-/- -:-:-"

export default function WS600(props) {
    const auths = useContext(AuthContext);
    const theme = useContext(ThemeContext);

    const [dialogVisible, setDialogVisible] = useState(false);
    const [filterState, setFilterState] = useState(false);
    const [shadowState, setShadowState] = useState(false);
    const [site, setSite] = useState(initSite);
    const [update, setUpdate] = useState(0);
    const [lastEvent, setLastEvent] = useState(initEvent);
    const [events, setEvents] = useState(initSite);

    // const [queryParameters] = useSearchParams();
    // const siteID = queryParameters.get("id");
    const navigate = useNavigate();

    const url = window.location.href;
    const siteID = url.split("=")[1];

    var startDate = "";
    var endDate = "";

    function getEvents(start,end) {
        const isoStart = start.toISOString().split("T")[0];
        const isoEnd = end.toISOString().split("T")[0];

        console.log("WS600:getEvents:start:",isoStart);
        console.log("WS600:getEvents:end:",isoEnd);

        const voltChart = ApexCharts.getChartByID('voltChart');
        const sigChart = ApexCharts.getChartByID('sigChart');
        const accumChart = ApexCharts.getChartByID('accumChart');
        const humidityChart = ApexCharts.getChartByID('humidityChart');
        const intenseChart = ApexCharts.getChartByID('intenseChart');
        const absAirPressChart = ApexCharts.getChartByID('absAirPressChart');
        const airTempChart = ApexCharts.getChartByID('airTempChart');
        const windChart = ApexCharts.getChartByID('windChart');

        absAirPressSeries = [{name:'Air Pressure',data:[]}];
        accumSeries = [{name:'Accumulation',data:[]}];
        airTempSeries = [{name:'Air Temperature',data:[]}];
        humiditySeries = [{name:'Humidity',data:[]}];
        intenseSeries = [{name:'Intensity',data:[]}];
        sigSeries = [{name:'Signal',data:[]}];
        voltSeries = [{name:'Voltage',data:[]}];
        windSpeedSeries = [{name:'Wind Speed',data:[]}];

        const queryStr = {
            query:`query EventsByDate($site:ID!,$start:AWSDate!,$end:AWSDate!){
                getEventsByDate(end: $end, site: $site, start: $start) {
                    nextToken
                  events {
                    msg,
                    pk,
                    sk
                }
                  site {
                    name,
                    comms,
                    msg,
                    description,
                    thingName,
                    sensors{
                        input,
                        make,
                        model,
                        slot
                    },
                    parameters,
                    location{
                        latitude,
                        longitude
                    },
                    address{
                        address_1,
                        address_2,
                        city,
                        state,
                        zip}
                    }
                }
            }`,
            variables: {
                site:siteID,
                start:isoStart,
                end:isoEnd
             }
        }

        console.log("Query:",JSON.stringify(queryStr))

        fetch('https://ebkrbuiepncdzkjpgclwpifxxi.appsync-api.us-east-1.amazonaws.com/graphql',{
            method:'POST',
            headers: {
                "Content-Type":"application/graphql",
                "x-api-key":"da2-6jq5ueouyjdrnnhkk6heqzd7ji"
            },
            body:JSON.stringify(queryStr)
        })
        .then(response => response.json())
        .then(resp => {

            console.log("WS600:Resp:",resp.data);

            // Convert the params to JSON
            for (var i in resp.data.getEventsByDate.site.parameters) {
                resp.data.getEventsByDate.site.parameters[i] = JSON.parse(resp.data.getEventsByDate.site.parameters[i])
            }

            // Convert Comms to JSON
            resp.data.getEventsByDate.site.comms = JSON.parse(resp.data.getEventsByDate.site.comms);

            // Convert the sites last message to JSON
            resp.data.getEventsByDate.site.msg = JSON.parse(resp.data.getEventsByDate.site.msg);

            setSite(prevSite => {
                return resp.data.getEventsByDate.site;
              });
            console.log("Site:",resp.data.getEventsByDate.site);

            // Setup voltage parameters
            voltParam = resp.data.getEventsByDate.site.parameters.find(p => { return p.type == "voltage"});
            console.log("WS600:volt param:",voltParam);
            voltChart.updateOptions(voltOptions);
            voltChart.updateOptions({
                yaxis : {
                    labels: {
                        formatter: function (value) {
                          return value.toFixed(1) + " v";
                        }
                    },
                    max:voltParam.max,
                    min:voltParam.min
                }
            },true);

            // Add voltage thresholds
            voltChart.addYaxisAnnotation({
                y:13.5,
                borderColor:"#17CC34",
                fillColor:"#17CC34",
                strokeDashArray:0,
                label: {
                    borderColor: '#17CC34',
                    style: {
                        color: '#FFFFFF',
                        background: '#17CC34'
                    },
                    text: "Excellent",
                    textAnchor:'start',
                    position:'left',
                    offsetX:10
                }
            });

            voltChart.addYaxisAnnotation({
                y:12.5,
                borderColor:"#FECC00",
                fillColor:"#FECC00",
                strokeDashArray:0,
                label: {
                    borderColor: '#FECC00',
                    style: {
                        color: '#FFFFFF',
                        background: '#FECC00'
                    },
                    text: "Good",
                    textAnchor:'start',
                    position:'left',
                    offsetX:10
                }
            });

            voltChart.addYaxisAnnotation({
                y:11,
                borderColor:"#FD0101",
                fillColor:"#FD0101",
                strokeDashArray:0,
                label: {
                    borderColor: '#FD0101',
                    style: {
                        color: '#FFFFFF',
                        background: '#FD0101'
                    },
                    text: "Low",
                    textAnchor:'start',
                    position:'left',
                    offsetX:10
                }
            });
          
            // Setup signal parameters
            signalParam = resp.data.getEventsByDate.site.parameters.find(p => { return p.type == "signal"});
            console.log("signal param:",signalParam);
            console.log("WS600:signal options:",signalOptions);
            sigChart.updateOptions({
                yaxis : {
                    labels: {
                        formatter: function (value) {
                          return value.toFixed(1) + " dB";
                        }
                    },
                    max:signalParam.max,
                    min:signalParam.min,
                    reversed:true,
                }
            },true);

            // Add signal strength thresholds
            sigChart.addYaxisAnnotation({
                y:-50,
                borderColor:"#17CC34",
                fillColor:"#17CC34",
                strokeDashArray:0,
                label: {
                    borderColor: '#17CC34',
                    style: {
                        color: '#FFFFFF',
                        background: '#17CC34'
                    },
                    text: "Excellent",
                    textAnchor:'start',
                    position:'left',
                    offsetX:10
                }
            });

            sigChart.addYaxisAnnotation({
                y:-70,
                borderColor:"#FECC00",
                fillColor:"#FECC00",
                strokeDashArray:0,
                label: {
                    borderColor: '#FECC00',
                    style: {
                        color: '#000000',
                        background: '#FECC00'
                    },
                    text: "Good",
                    textAnchor:'start',
                    position:'left',
                    offsetX:10
                }
            });

            sigChart.addYaxisAnnotation({
                y:-100,
                borderColor:"#FE8700",
                fillColor:"#FE8700",
                strokeDashArray:0,
                label: {
                    borderColor: '#FE8700',
                    style: {
                        color: '#000000',
                        background: '#FE8700'
                    },
                    text: "Weak",
                    textAnchor:'start',
                    position:'left',
                    offsetX:10
                }
            });

            // Setup accumulation parameters
            accumParam = resp.data.getEventsByDate.site.parameters.find(p => { return p.type == "precipAccum"});
            console.log("accumulation param:",accumParam);
            console.log("WS600:accumulation options:",accumOptions);
            accumChart.updateOptions({
                yaxis : {
                    labels: {
                        formatter: function (value) {
                          return value.toFixed(1) + " in";
                        }
                    },
                    max:accumParam.max,
                    min:accumParam.min
                }
            },true);

            // Add precip accumulation thresholds
            accumChart.addYaxisAnnotation({
                y:0.4,
                borderColor:"#17CC34",
                fillColor:"#17CC34",
                strokeDashArray:0,
                label: {
                    borderColor: '#17CC34',
                    style: {
                        color: '#FFFFFF',
                        background: '#17CC34'
                    },
                    text: "Light",
                    textAnchor:'start',
                    position:'left',
                    offsetX:10
                }
            });

            accumChart.addYaxisAnnotation({
                y:1,
                borderColor:"#FFFF00",
                fillColor:"#FFFF00",
                strokeDashArray:0,
                label: {
                    borderColor: '#FFFF00',
                    style: {
                        color: '#000000',
                        background: '#FFFF00'
                    },
                    text: "Moderate",
                    textAnchor:'start',
                    position:'left',
                    offsetX:10
                }
            })

            accumChart.addYaxisAnnotation({
                y:2,
                borderColor:"#FE9900",
                fillColor:"#FE9900",
                strokeDashArray:0,
                label: {
                    borderColor: '#FE9900',
                    style: {
                        color: '#fff',
                        background: '#FE9900'
                    },
                    text: "Heavy",
                    textAnchor:'start',
                    position:'left',
                    offsetX:10
                }
            })

            accumChart.addYaxisAnnotation({
                y:4,
                borderColor:"#FD0101",
                fillColor:"#FD0101",
                strokeDashArray:0,
                label: {
                    borderColor: '#FD0101',
                    style: {
                        color: '#FFFFFF',
                        background: '#FD0101'
                    },
                    text: "Storm",
                    textAnchor:'start',
                    position:'left',
                    offsetX:10
                }
            })

            accumChart.addYaxisAnnotation({
                y:10,
                borderColor:"#9900FF",
                fillColor:"#9900FF",
                strokeDashArray:0,
                label: {
                    borderColor: '#9900FF',
                    style: {
                        color: '#FFFFFF',
                        background: '#9900FF'
                    },
                    text: "Excessive Storm",
                    textAnchor:'start',
                    position:'left',
                    offsetX:10
                }
            })

            // Setup humidity parameters
            humidityParam = resp.data.getEventsByDate.site.parameters.find(p => { return p.type == "humidityRel"});
            console.log("WS600:humidity param:",humidityParam);
            console.log("WS600:humidity options:",humidityOptions);
            humidityChart.updateOptions({
                yaxis : {
                    labels: {
                        formatter: function (value) {
                          return value.toFixed(1) + " %";
                        }
                    },
                    max:humidityParam.max,
                    min:humidityParam.min
                }
            },true);

            // Add humidity intensity thresholds
            humidityChart.addYaxisAnnotation({
                y:90,
                borderColor:"#FFFF00",
                fillColor:"#FFFF00",
                strokeDashArray:0,
                label: {
                    borderColor: '#FFFF00',
                    style: {
                        color: '#000000',
                        background: '#FFFF00'
                    },
                    text: "High",
                    textAnchor:'start',
                    position:'left',
                    offsetX:10
                }
            });

            humidityChart.addYaxisAnnotation({
                y:60,
                borderColor:"#FE9900",
                fillColor:"#FE9900",
                strokeDashArray:0,
                label: {
                    borderColor: '#FE9900',
                    style: {
                        color: '#000000',
                        background: '#FE9900'
                    },
                    text: "Average",
                    textAnchor:'start',
                    position:'left',
                    offsetX:10
                }
            });

            humidityChart.addYaxisAnnotation({
                y:30,
                borderColor:"#FD0101",
                fillColor:"#FD0101",
                strokeDashArray:0,
                label: {
                    borderColor: '#FD0101',
                    style: {
                        color: '#FFFFFF',
                        background: '#FD0101'
                    },
                    text: "Low",
                    textAnchor:'start',
                    position:'left',
                    offsetX:10
                }
            });

            // Setup intensity parameters
            intenseParam = resp.data.getEventsByDate.site.parameters.find(p => { return p.type == "precipIntensity"});
            console.log("intensity param:",intenseParam);
            console.log("WS600:intensity options:",intenseOptions);
            intenseChart.updateOptions({
                yaxis : {
                    labels: {
                        formatter: function (value) {
                          return value.toFixed(1) + " in/hr";
                        }
                    },
                    max:intenseParam.max,
                    min:intenseParam.min
                }
            },true);

            // Add precip intensity thresholds
            intenseChart.addYaxisAnnotation({
                y:0.2,
                borderColor:"#FFFF00",
                fillColor:"#FFFF00",
                strokeDashArray:0,
                label: {
                    borderColor: '#FFFF00',
                    style: {
                        color: '#000000',
                        background: '#FFFF00'
                    },
                    text: "Slight",
                    textAnchor:'start',
                    position:'left',
                    offsetX:10
                }
            });

            intenseChart.addYaxisAnnotation({
                y:0.5,
                borderColor:"#FE9900",
                fillColor:"#FE9900",
                strokeDashArray:0,
                label: {
                    borderColor: '#FE9900',
                    style: {
                        color: '#000000',
                        background: '#FE9900'
                    },
                    text: "Moderate",
                    textAnchor:'start',
                    position:'left',
                    offsetX:10
                }
            });

            intenseChart.addYaxisAnnotation({
                y:2,
                borderColor:"#FD0101",
                fillColor:"#FD0101",
                strokeDashArray:0,
                label: {
                    borderColor: '#FD0101',
                    style: {
                        color: '#FFFFFF',
                        background: '#FD0101'
                    },
                    text: "Heavy",
                    textAnchor:'start',
                    position:'left',
                    offsetX:10
                }
            });

            // Setup absolute air pressure parameters
            absAirPressParam = resp.data.getEventsByDate.site.parameters.find(p => { return p.type == "airPressureAbs"});
            console.log("WS600:abs air press. param:",absAirPressParam);
            console.log("WS600:abs air press. options:",absAirPressOptions);
            absAirPressChart.updateOptions({
                yaxis : {
                    labels: {
                        formatter: function (value) {
                          return value.toFixed(1) + " hPa";
                        }
                    },
                    max:absAirPressParam.max,
                    min:absAirPressParam.min
                }
            },true);

            // Add air pressure thresholds
            absAirPressChart.addYaxisAnnotation({
                y:1110,
                borderColor:"#FFFF00",
                fillColor:"#FFFF00",
                strokeDashArray:0,
                label: {
                    borderColor: '#FFFF00',
                    style: {
                        color: '#000000',
                        background: '#FFFF00'
                    },
                    text: "High",
                    textAnchor:'start',
                    position:'left',
                    offsetX:10
                }
            });

            absAirPressChart.addYaxisAnnotation({
                y:800,
                borderColor:"#FE9900",
                fillColor:"#FE9900",
                strokeDashArray:0,
                label: {
                    borderColor: '#FE9900',
                    style: {
                        color: '#000000',
                        background: '#FE9900'
                    },
                    text: "Average",
                    textAnchor:'start',
                    position:'left',
                    offsetX:10
                }
            });

            absAirPressChart.addYaxisAnnotation({
                y:400,
                borderColor:"#FD0101",
                fillColor:"#FD0101",
                strokeDashArray:0,
                label: {
                    borderColor: '#FD0101',
                    style: {
                        color: '#FFFFFF',
                        background: '#FD0101'
                    },
                    text: "Low",
                    textAnchor:'start',
                    position:'left',
                    offsetX:10
                }
            });

            // Setup air temperature parameters
            airTempParam = resp.data.getEventsByDate.site.parameters.find(p => { return p.type == "airTemp"});
            console.log("WS600:air temp. param:",airTempParam);
            console.log("WS600:voltage options:",airTempOptions);

            airTempChart.updateOptions({
                yaxis : {
                    labels: {
                        formatter: function (value) {
                          return value.toFixed(1) + " F";
                        }
                    },
                    max:airTempParam.max,
                    min:airTempParam.min
                }
            },true);

            // Add air temp. thresholds
            airTempChart.addYaxisAnnotation({
                y:100,
                borderColor:"#FA6601",
                fillColor:"#FA6601",
                strokeDashArray:0,
                label: {
                    borderColor: '#FA6601',
                    style: {
                        color: '#FFFFFF',
                        background: '#FA6601'
                    },
                    text: "Very Hot",
                    textAnchor:'start',
                    position:'left',
                    offsetX:10
                }
            });

            airTempChart.addYaxisAnnotation({
                y:90,
                borderColor:"#FCCC00",
                fillColor:"#FCCC00",
                strokeDashArray:0,
                label: {
                    borderColor: '#FCCC00',
                    style: {
                        color: '#FFFFFF',
                        background: '#FCCC00'
                    },
                    text: "Hot",
                    textAnchor:'start',
                    position:'left',
                    offsetX:10
                }
            });

            airTempChart.addYaxisAnnotation({
                y:60,
                borderColor:"#01D1D1",
                fillColor:"#01D1D1",
                strokeDashArray:0,
                label: {
                    borderColor: '#01D1D1',
                    style: {
                        color: '#000000',
                        background: '#01D1D1'
                    },
                    text: "Cold",
                    textAnchor:'start',
                    position:'left',
                    offsetX:10
                }
            });

            airTempChart.addYaxisAnnotation({
                y:32,
                borderColor:"#A5D6FF",
                fillColor:"#A5D6FF",
                strokeDashArray:0,
                label: {
                    borderColor: '#A5D6FF',
                    style: {
                        color: '#000000',
                        background: '#A5D6FF'
                    },
                    text: "Freezing",
                    textAnchor:'start',
                    position:'left',
                    offsetX:10
                }
            });

            // Setup wind speed parameters
            windSpeedParam = resp.data.getEventsByDate.site.parameters.find(p => { return p.type == "windSpeed"});
            console.log("WS600:wind speed param:",windSpeedParam);
            console.log("WS600:wind spped options:",windSpeedOptions);

            windChart.updateOptions({
                yaxis : {
                    labels: {
                        formatter: function (value) {
                          return value.toFixed(1) + " mph";
                        }
                    },
                    max:windSpeedParam.max,
                    min:windSpeedParam.min
                }
            },true);

            // Add air pressure thresholds
            windChart.addYaxisAnnotation({
                y:110,
                borderColor:"#FFFF00",
                fillColor:"#FFFF00",
                strokeDashArray:0,
                label: {
                    borderColor: '#FFFF00',
                    style: {
                        color: '#000000',
                        background: '#FFFF00'
                    },
                    text: "High",
                    textAnchor:'start',
                    position:'left',
                    offsetX:10
                }
            });

            windChart.addYaxisAnnotation({
                y:80,
                borderColor:"#FE9900",
                fillColor:"#FE9900",
                strokeDashArray:0,
                label: {
                    borderColor: '#FE9900',
                    style: {
                        color: '#000000',
                        background: '#FE9900'
                    },
                    text: "Average",
                    textAnchor:'start',
                    position:'left',
                    offsetX:10
                }
            });

            windChart.addYaxisAnnotation({
                y:40,
                borderColor:"#FD0101",
                fillColor:"#FD0101",
                strokeDashArray:0,
                label: {
                    borderColor: '#FD0101',
                    style: {
                        color: '#FFFFFF',
                        background: '#FD0101'
                    },
                    text: "Low",
                    textAnchor:'start',
                    position:'left',
                    offsetX:10
                }
            });

            // Setup wind direction parameters


            // Setup Event related components
            if (resp.data.getEventsByDate.events.length === 0) return;

            // Save events
            setEvents(resp.data.getEventsByDate.events);

            // Get last event
            var LastEvent = resp.data.getEventsByDate.site.msg;
            setLastEvent(LastEvent);

            lastUpdate = new Date(LastEvent.timestamp * 1000).toLocaleString();
            console.log("WS600:last update:",lastEvent.timestamp);
            console.log("WS600:Last Event:",LastEvent);

            // Setup voltage value
            voltValue = LastEvent.ina219.voltage;

            // Setup Signal
            if("lte" in LastEvent) {
                signalValue = LastEvent.lte.rssi
            }

            if("wifi" in LastEvent) {
                signalValue = LastEvent.wifi.rssi
            }
            accumValue = LastEvent.lufft.precip.abs;
            intenseValue = LastEvent.lufft.precip.intensity;
            precipType = lastEvent.lufft.precip.type;
            absAirPressValue = LastEvent.lufft.airPress.abs;
            airTempValue = LastEvent.lufft.airTemp;
            windSpeedValue = LastEvent.lufft.windSpeed;
            humidityValue = LastEvent.lufft.humidity.rel;

            resp.data.getEventsByDate.events.forEach(event => {
                var evt = JSON.parse(event.msg);
                var dt = event.sk.replace("EVENT#","")
                dt += "Z";

                // console.log("evt:",evt);
                voltSeries[0].data.push({x:dt, y:(evt.ina219.voltage).toFixed(2)});

                if("lte" in evt) {
                    sigSeries[0].data.push({x:dt,y:(evt.lte.rssi).toFixed(2)});
                }

                if("wifi" in evt) {
                    sigSeries[0].data.push({x:dt,y:(evt.wifi.rssi).toFixed(2)});
                }

                accumSeries[0].data.push({x:dt,y:evt.lufft.precip.abs});
                humiditySeries[0].data.push({x:dt,y:evt.lufft.humidity.rel});
                intenseSeries[0].data.push({x:dt,y:evt.lufft.precip.intensity});
                absAirPressSeries[0].data.push({x:dt,y:evt.lufft.airPress.abs});
                airTempSeries[0].data.push({x:dt,y:evt.lufft.airTemp});
                windSpeedSeries[0].data.push({x:dt,y:evt.lufft.windSpeed});
            })

            accumChart.updateSeries(accumSeries,true);
            humidityChart.updateSeries(humiditySeries,true);
            intenseChart.updateSeries(intenseSeries,true);
            absAirPressChart.updateSeries(absAirPressSeries,true);
            airTempChart.updateSeries(airTempSeries,true);
            windChart.updateSeries(windSpeedSeries,true);
        })
    }

    useEffect(() => {
        let s = new Date();
        let e = new Date();

        console.log("WS600 useEffect:start");
        s.setDate(e.getDate() - 7);
        e.setDate(e.getDate() + 1);
        getEvents(s,e);
        console.log("WS600 useEffect:end");
    },[props]);

    const wrapper = {
        width:'100%'
    }

    const backStyle = {
        position:'relative',
        top:-45,
        color:'white'
    }

    const dateStyle = {
        margin:10 
    }

    const dialogStyle = {
        zIndex:100
    }

    const dialogGridStyle = {
        justifyContent:"start",
        gridTemplateColumns: 'auto auto'
    }

    const filledButtonStyle = {
        margin:"auto",
        marginTop:20,
        marginBottom:20
    }

    const filterIcon = {
        position:'absolute',
        right:5,
        top:5
    };

    const gridStyle = {
        marginTop:40,
        gap:20
    }

    const InfoIcon = {
        position:'absolute',
        right:50,
        top:5
    };

    const labelStyle = {
        width:300,
        marginTop:20
    }

    const levelStyle = {
        marginTop:40,
        backgroundColor:'rgb(255,255,255)',
        boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.26)"
    }

    const linkStyle = {
        fontSize:21,
        textDecoration: 'none',
        color:'rgb(32,32,32)'
    }

    const radioStyle = {
        marginBottom:20,
        color: "rgb(96, 96, 96)"
    }

    const radioGroupStyle = {
        margin:10
    }

    function onFilterOpen(){
        setShadowState(true);
        setFilterState(true);
    }
    
    function onShadow() {
        setShadowState(false);
        setFilterState(false);
        setDialogVisible(false);
    }

    function onInfoOpen() {
        setShadowState(true);
        setDialogVisible(true);
    }

    function onRadioChange(e) {
        startDate = new Date();
        endDate = new Date();

        startDate.setDate(endDate.getDate() - e.target.value);
        console.log("onRadioChange:",startDate,endDate);
    };

    function onStartDate(e) {
        startDate = new Date(e.target.value);
        console.log("onStartDate:",startDate);
    }

    function onEndDate(e) {
        endDate = new Date(e.target.value);
        console.log("onEndDate:",endDate);
    }

    function onOK(e) {
        endDate.setDate(endDate.getDate() + 1);

        getEvents(startDate,endDate);
        setShadowState(false);
        setFilterState(false);
        setDialogVisible(false);
    }

    function onCancel(e) {
        setShadowState(false);
        setFilterState(false);
        setDialogVisible(false);
    }

    function onDialogClose(e) {
        setShadowState(false);
        setFilterState(false);
        setDialogVisible(false);
    }

    return (
        <div className="WS600" style={wrapper} onClick={props.onClick}>
            {/* {console.log("stage:",stageParam,stageValue)} */}
            {console.log("voltage:",voltParam,voltValue)}
            {console.log("signal:",signalParam,signalValue)}

            <Dialog 
                style={dialogStyle} 
                visible={dialogVisible}
                title="Site Overview"
                onClose = {onDialogClose}
            >
                <Grid style={dialogGridStyle}>
                    <GridItem>
                        <Label title="Name">{site.name}</Label>
                        <Label title="Description" style={labelStyle}>{site.description}</Label>
                        <LocationLabel location={site.location} />
                        <AddressLabel address={site.address}/>
                        <SensorsLabel sensors={site.sensors}></SensorsLabel>
                        <CommsLabel comms={site.comms}></CommsLabel>
                    </GridItem>
                    <GridItem>
                        <Label title="Stage"></Label>
                        <span style={{marginTop:5,fontSize:14, color:"rgb(92,92,92"}}>Min:</span>

                        <div style={{marginTop:5,marginBottom:5,fontSize:14,color:"rgb(92,92,92"}}>Thresholds:</div>

                        <Label title="Voltage" style={{marginTop:20}}></Label>
                        <span style={{marginTop:5,fontSize:14, color:"rgb(92,92,92"}}>Min:</span>
                        <span style={{marginLeft:5,fontSize:14, fontWeight:550}}>{voltParam.min}</span>
                        <span style={{marginLeft:5,fontSize:14, fontWeight:550}}>v</span>

                        <span style={{marginTop:5,marginLeft:10,fontSize:14, color:"rgb(92,92,92"}}>Max:</span>
                        <span style={{marginLeft:5,fontSize:14, fontWeight:550}}>{voltParam.max}</span>
                        <span style={{marginLeft:5,fontSize:14, fontWeight:550}}>v</span>

                        <Label title="Signal" style={{marginTop:20}}></Label>
                        <span style={{marginTop:5,fontSize:14, color:"rgb(92,92,92"}}>Min:</span>
                        <span style={{marginLeft:5,fontSize:14, fontWeight:550}}>{signalParam.min}</span>
                        <span style={{marginLeft:5,fontSize:14, fontWeight:550}}>dB</span>

                        <span style={{marginTop:5,marginLeft:10,fontSize:14, color:"rgb(92,92,92"}}>Max:</span>
                        <span style={{marginLeft:5,fontSize:14, fontWeight:550}}>{signalParam.max}</span>
                        <span style={{marginLeft:5,fontSize:14, fontWeight:550}}>dB</span>
                    
                    </GridItem>
                </Grid>
            </Dialog>
            <Shadow visible = {shadowState} onClose={onShadow}/>
            <Drawer 
                anchor = 'right'
                visible = {filterState} 
                title = "Filter"
            >
                <div style={radioGroupStyle} onChange={onRadioChange}>
                    <RadioButton style={radioStyle} value="1" name="filter" label="Today" />
                    <RadioButton style={radioStyle} value="2" name="filter" label="Last 2 Days" />
                    <RadioButton style={radioStyle} value="7" name="filter" label="Last 7 Days" />
                    <RadioButton style={radioStyle} value="14" name="filter" label="Last 14 Days" />
                    <RadioButton style={radioStyle} value="30" name="filter" label="Last 30 Days" />
                </div>
                <DateField style={dateStyle} onBlur={onStartDate}></DateField>
                <DateField style={dateStyle} onBlur={onEndDate}></DateField>
                <FilledButton style={filledButtonStyle} onSelect={onOK}>Ok</FilledButton>
                <FilledButton style={filledButtonStyle} onSelect={onCancel}>Cancel</FilledButton>
            </Drawer>

            <AppBar title = {site.name} >
                <BackButton style={backStyle} onSelect={() => navigate(-1)}></BackButton>
                <Link style={linkStyle} to={'/Info' + '?id=' + site.thingName} >
                    <InfoIconButton style={InfoIcon} />
                </Link>                
                <FilterIconButton style={filterIcon} onSelect={onFilterOpen}/>
            </AppBar>
            <div style={{textAlign:"center",marginTop:5}}>{lastUpdate.toLocaleString()}</div>
            <Grid className="cards" style={gridStyle}>
                <GridItem>
                    <PrecipTypeCard 
                        name="Precip. Type"
                        type = {precipType}
                    />  
                </GridItem>
                <GridItem>
                    <StatusCard 
                        name="Precip. Intensity"
                        param = {intenseParam}
                        value={intenseValue}
                        unit = 'in/hr'
                        status=""
                    />  
                </GridItem>
                <GridItem>
                    <StatusCard 
                        name="Precip. Accum."
                        param = {accumParam}
                        value={accumValue}
                        unit = 'in'
                        status=""
                    />  
                </GridItem>
                <GridItem>
                    <StatusCard 
                        name="Wind Speed"
                        param = {windSpeedParam}
                        value={windSpeedValue}
                        unit = 'mph'
                        status=""
                    />  
                </GridItem>
                <GridItem>
                    <StatusCard 
                        name="Air Temp."
                        param = {airTempParam}
                        value={airTempValue}
                        unit = 'F'
                        status=""
                    />  
                </GridItem>
                <GridItem>
                    <StatusCard 
                        name="Air Pressure"
                        param = {absAirPressParam}
                        value={absAirPressValue}
                        unit = 'hPa'
                        status=""
                    />  
                </GridItem>
                <GridItem>
                    <StatusCard 
                        name="Rel. Humidity"
                        param = {humidityParam}
                        value={humidityValue}
                        unit = '%'
                        status=""
                    />  
                </GridItem>
                <GridItem>
                    <StatusCard 
                        name="Voltage"
                        param = {voltParam}
                        value = {voltValue}
                        unit = 'v'
                        status=""
                    />  
                </GridItem>
                <GridItem>
                    <StatusCard 
                        name="Signal Strength"
                        param = {signalParam}
                        value={signalValue}
                        unit = 'dB'
                        status=""
                    />  
                </GridItem>
            </Grid>
            <Chart
                style={levelStyle}
                options={intenseOptions}
                series={intenseSeries}
                type="area"
                width="100%"
                height="400px"
            />
            <Chart
                style={levelStyle}
                options={accumOptions}
                series={accumSeries}
                type="area"
                width="100%"
                height="400px"
            />
            <Chart
                style={levelStyle}
                options={windSpeedOptions}
                series={windSpeedSeries}
                type="area"
                width="100%"
                height="400px"
            />
            <Chart
                style={levelStyle}
                options={airTempOptions}
                series={airTempSeries}
                type="area"
                width="100%"
                height="400px"
            />
            <Chart
                style={levelStyle}
                options={absAirPressOptions}
                series={absAirPressSeries}
                type="area"
                width="100%"
                height="400px"
            />
            <Chart
                style={levelStyle}
                options={humidityOptions}
                series={humiditySeries}
                type="area"
                width="100%"
                height="400px"
            />
            <Chart
                style={levelStyle}
                options={voltOptions}
                series={voltSeries}
                type="area"
                width="100%"
                height="400px"
            />
            <Chart
                style={levelStyle}
                options={signalOptions}
                series={sigSeries}
                type="area"
                width="100%"
                height="400px"
            />
        </div>
    )
};