import React from 'react';
import { useState, useContext, useEffect } from "react";
import { AuthContext } from '../../../App';
import { ThemeContext } from '../../../App.js';

const stage = {
    max:100,
    min:0,
    offset:20,
    thresholds: [
        {name:"Action Needed",color:"rgb(255,255,0)",value:1000},
        {name:"Minor Flooding",color:"rgb(0,64,128)",value:2000},
        {name:"Moderate Flooding",color:"rgb(0,255,255)",value:5000},
        {name:"Major Flooding",color:"rgb(255,0,0)",value:7500}
    ]
}

export default function Stage(props) {
    const auths = useContext(AuthContext);
    const theme = useContext(ThemeContext);
    const [isOnline, setIsOnline] = useState("offline");
    const [status, setStatus] = useState("No Flooding");

    const wrapper = {
        position:'relative',
        width:300,
        height:150,
        backgroundColor:"rgb(255,255,255)",
        borderRadius:8,
        padding:4,
        boxSizing: 'border-box',
        // boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.26)"
        boxShadow: "rgba(149,157,165,0.2) 0px 8px 24px"

    }

    const activeStyle = {
        position:'absolute',
        fontSize:12,
        bottom:5,
        left:5
    }

    const dateStyle = {
        position:"absolute",
        bottom:5,
        right:5,
        fontSize:12,
        marginTop:5,
        color:'rgb(128,128,128)'
    }

    const levelStyle = {
        display:'inline',
        fontSize:21,
        fontWeight:"normal",
        color:"rgb(128,128,128)"
    }

    const levelWrapper = {
        position:'relative',
        width:'100%',
        marginTop:5,
        textAlign:"center"
    }

    const nameStyle = {
        fontSize:16,
        fontWeight:"bold"
    }

    const unitStyle = {
        display:'inline',
        fontSize:21,
        marginLeft:5,
        color:"rgb(128,128,128)"
    }

    const statusStyle = {
        display:"inline-block",
        fontSize:32,
        fontWeight:'bold',
        textAlign:'center',
        color:'rgb(0,0,0)'
    }

    const statusIconStyle = {
        display:"inline-block",
        width:24,
        height:24,
        marginRight:10,
        backgroundColor:"rgb(23, 204, 52)",
        borderRadius:4
    }

    const statusWrapper = {
        position:'relative',
        width:'100%',
        marginTop:20,
        textAlign:"center"
    }

    function findReading(name) {
        console.log("StageOverview:findReading:",name);

        for (var i in props.site.readings) {
            if(props.site.readings[i].type == name) {
                return props.site.readings[i];
            }
        }
        console.log("StageOverview:unknown type:",name);
        return null;
    }

    // Setup
    var tStatus = "Normal";
    var lastUpdate;
    var level;
    var levelMM;
    var reading;

    console.log("StageOverview:site:",props.site);
    console.log("StageOverview:readings:",props.site.readings);

    {props.site.active ? activeStyle.color = "green" : activeStyle.color="red"}

    if(props.site.message != null) {
        reading = findReading("stage");
        lastUpdate = new Date(props.site.message.timestamp * 1000).toLocaleString();

        if(props.site.message.maxbotix != null ) {
            levelMM = (reading.elevation - props.site.message.maxbotix.mm + reading.MLLW);
            level = (levelMM / 304.8).toFixed(2);
        } else if(props.site.message.senix != null) {
            levelMM = (reading.elevation - props.site.message.senix.mm + reading.MLLW);
            level = (levelMM / 304.8).toFixed(2);
        } else if(props.site.message.csi != null) {
            levelMM = (props.site.message.csi.basic * 304.8);
            level = (reading.elevation - props.site.message.csi.basic.ft + reading.MLLW).toFixed(2);
        } else {
            levelMM = 0;
            level = 0;
        }
        
        reading.thresholds.forEach( threshold => {
            if(levelMM > (threshold.value + reading.MLLW)) {
                tStatus = threshold.name;
            }
        })

        console.log("StageOverview:reading:",reading);
        console.log("StageOverview:level:mm:",levelMM);
        console.log("StageOverview:level:feet:",level);
        console.log("StageOverview:threshold:",tStatus);

    } else {
        console.log("StageOverview:No Message");
        reading = findReading("stage");
        lastUpdate = "-/-/- -:-:-";
        levelMM = 0;
        level = 0;
        tStatus = "No Message";

        // reading.thresholds.forEach( threshold => {
        //     if(levelMM > (threshold.value + reading.MLLW)) {
        //         tStatus = threshold.name;
        //     }
        // })

        console.log("StageOverview:reading:",reading);
        console.log("StageOverview:level:mm:",levelMM);
        console.log("StageOverview:level:feet:",level);
        console.log("StageOverview:threshold:",tStatus);
    }

    return (
        <div style={wrapper} id="siteStatus">
            <div style={nameStyle} id="name">{props.site.name}</div>
            <div style={activeStyle} id="active">{props.site.active ? 'Online' : 'Offline'}</div>
            <div style={dateStyle} id="lastUpdate">{lastUpdate}</div>
            <div style={statusWrapper}>
                <div style={statusIconStyle}></div>
                <div style={statusStyle}>{tStatus}</div>
            </div>
            <div style={levelWrapper}>
                <div style={levelStyle}>{level}</div>
                <div style={unitStyle}>ft.</div>
            </div>
        </div>
    )
}