import React from 'react';
import { useState, useContext, useEffect, useRef } from "react";
import { Link,useSearchParams,useNavigate } from "react-router-dom";

import AppBar from '../components/AppBar.js';
import Grid from '../components/Grid.js';
import GridItem from '../components/GridItem.js';
import Cards from "../components/cards/overview/Cards.js";
import SearchBar from '../components/SearchBar.js';
import BackButton from '../components/buttons/BackButton.js';
import GridButton from '../components/buttons/Grid.js';
import { AuthContext } from '../App';
import { ThemeContext } from '../App';

var online = 0;
var offline = 0;

var initSortConfig = {
    status:true,
    name:true,
    level:true,
    flood:true,
    lastUpdate:true
}

export default function SitesTableByCounty(props) {
    // const auths = useContext(AuthContext);
    const theme = useContext(ThemeContext);
    const elemRef = useRef([]);
    const [sites, setSites] = useState([]);
    const [queryParameters] = useSearchParams();
    const [sortConfig, setSortConfig] = useState(initSortConfig);
    const navigate = useNavigate();
    const countyID = queryParameters.get("county");

    const [nameClass, setNameClass] = useState("name");
    const [statusClass, setStatusClass] = useState("status");
    const [levelClass, setLevelClass] = useState("level");
    const [floodClass, setFloodClass] = useState("flood");
    const [lastUpdateClass, setLastUpdateClass] = useState("lastUpdate");

    {console.log("SitesTableByCounty:Theme:",theme)}

    useEffect(() => {
        console.log("countyID:",countyID);
        const queryStr = {
            query: `query SitesByCounty($county: String) {
                getSitesByCounty(county: $county) {
                    msg,
                    active,
                    name,
                    parameters,
                    tags,
                    thingName,
                    lastUpdate
                }
            }`,
            variables: {
                "county":countyID
            }
        }
    
        fetch('https://ebkrbuiepncdzkjpgclwpifxxi.appsync-api.us-east-1.amazonaws.com/graphql',{
            method:'POST',
            headers: {
                "Content-Type":"application/graphql",
                "x-api-key":"da2-6jq5ueouyjdrnnhkk6heqzd7ji"
            },
            body:JSON.stringify(queryStr)
        })
        .then(response => response.json())
        .then(resp => {
            var sites;

            online = 0;
            offline = 0;

            console.log("resp:",resp.data.getSitesByCounty);

            resp.data.getSitesByCounty.forEach(item => {
                item.msg = JSON.parse(item.msg);
                item.tags = JSON.parse(item.tags);
                for(var i in item.parameters) {
                    item.parameters[i] = JSON.parse(item.parameters[i]);
                }
                item["visible"] = 'block';

                if(item.active) {
                    online += 1;
                } else {
                    offline += 1;
                }
            })
            
            sites = resp.data.getSitesByCounty.sort((a, b) => {
                if(a.name < b.name) {
                    return -1;
                } 

                if (a.name > b.name) {
                    return 1;
                }
                
                return 0;
            });
            
            console.log("Sites:",sites);
            setSites(sites);
        })
    }, [props.update]);

    const wrapper = {
        width:'100%',
        marginBottom:40
    }

    var activeStyle = {
 
    }

    const appBarStyle = {
        position:'fixed',
        top:0,
        zIndex:1
    }

    const backLinkStyle = {
        position:'absolute',
        top:4,
        left:5,
        fontSize:21,
        textDecoration: 'none',
        color:'rgb(32,32,32)'
    }

    const headerRow = {
        color:"black",
        textAlign:"center"
    }

    const sitestyle = {
        display:"block"
    }

    const gridStyle = {
        fontSize:21,
        textDecoration: 'none',
        color:'rgb(32,32,32)'
    }

    const linkStyle = {
        fontSize:21,
        textDecoration: 'none',
        color:'rgb(32,32,32)'
    }

    const searchStyle = {
        position:'fixed',
        top:96,
        zIndex:1
    }

    const statusStyle = {
        position:'fixed',
        top:48,
        left:0,
        right:0,
        height:48,
        background:'rgb(255,255,255)',
        lineHeight:"48px",
        borderBottom:"solid 1px rgb(240,240,240)",
        textAlign :'center',
        zIndex:1
    }

    const tableLinkStyle = {
        position:'absolute',
        top:4,
        right:5,
        fontSize:21,
        textDecoration: 'none',
        color:'rgb(32,32,32)'
    }

    const tableStyle = {
        color:'black',
        width:"90%",
        margin:'auto',
        marginTop:165,
        marginBottom:20,
        background:'white',
        borderRadius:4,
        boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.26)"
    }

    const nameHeaderStyle = {
        textAlign:"left", 
        paddingLeft:5, 
        cursor:"pointer"
    }

    function onClear(e) {
        for(var i in elemRef.current) {
            elemRef.current[i].style.display = "block";
        }
    }

    function onSearch(e) {
        var tmp = sites;

        console.log("onSearch:",e.target.value);
        for(var i in sites) {
            if(sites[i].name.toLowerCase().startsWith(e.target.value.toLowerCase())) {
                elemRef.current[i].style.display = "block";
                console.log(elemRef.current[i]);
            } else {
               elemRef.current[i].style.display = "none";
            }
        }
    }

    function findParam(params,type) {
        for(var i=0; i<params.length; i++) {
            if(params[i].type == type) {
                return params[i]
            }
        }
    }

    function findThreshold(thresholds,level) {
        var result = "No Flooding";

        console.log()
        for(var i=0; i<thresholds.length; i++) {
            if(level > thresholds[i].value) {
                result = thresholds[i].name;
            }
        }
        return result;
    }

    function handleMouseEnter(e) {
        e.currentTarget.style.backgroundColor = "rgb(240,240,240)";
    }

    function handleMouseLeave(e) {
        e.currentTarget.style.backgroundColor = "rgb(255,255,255)";
    }

    function handleRowClick(e,d,i) {
        console.log("handleRowClick:",i);
        navigate('/' + d + '?id=' + i)
    }

    function sortFlood(e) {
        console.log("sort by flood:",sortConfig);

        if (sortConfig.level == true) {
            sites.sort((a, b) => {
                if(a.site.flood < b.site.flood) {
                    return -1;
                } 
    
                if (a.site.flood > b.site.flood) {
                    return 1;
                }
                
                return 0;
            });
            setNameClass("name");
            setStatusClass("status");
            setLevelClass("level");
            setFloodClass("flood ascend");
            setLastUpdateClass("lastUpdate");

            setSortConfig({...sortConfig,flood:false});
            setSites(sites);
        } else {
            sites.sort((a, b) => {
                if(a.site.flood < b.site.flood) {
                    return 1;
                } 
    
                if (a.site.flood > b.site.flood) {
                    return -1;
                }
                
                return 0;
            });
            setNameClass("name");
            setStatusClass("status");
            setLevelClass("level");
            setFloodClass("flood descend");
            setLastUpdateClass("lastUpdate");

            setSortConfig({...sortConfig,flood:true});
            setSites(sites);
        }
    }

    function sortLastUpdate(e) {
        console.log("sort by last update:",sortConfig);

        if (sortConfig.level == true) {
            sites.sort((a, b) => {
                if(a.site.msg === null) return 0;
                if(b.site.msg === null) return 0;

                if(a.site.msg.timestamp < b.site.msg.timestamp) {
                    return -1;
                } 
    
                if (a.site.msg.timestamp > b.site.msg.timestamp) {
                    return 1;
                }
                
                return 0;
            });
            setNameClass("name");
            setStatusClass("status");
            setLevelClass("level");
            setFloodClass("flood");
            setLastUpdateClass("lastUpdate ascend");

            setSortConfig({...sortConfig,lastUpdate:false});
            setSites(sites);
        } else {
            sites.sort((a, b) => {
                if(a.site.msg === null) return 0;
                if(b.site.msg === null) return 0;

                if(a.site.msg.timestamp < b.site.msg.timestamp) {
                    return 1;
                } 
    
                if (a.site.msg.timestamp > b.site.msg.timestamp) {
                    return -1;
                }
                
                return 0;
            });
            setNameClass("name ascend");
            setStatusClass("status");
            setLevelClass("level");
            setFloodClass("flood");
            setLastUpdateClass("lastUpdate descend");

            setSortConfig({...sortConfig,lastUpdate:true});
            setSites(sites);
        }
    }

    function sortLevel(e) {
        console.log("sort by level:",sortConfig);

        if (sortConfig.level == true) {
            sites.sort((a, b) => {
                if(a.site.level < b.site.level) {
                    return -1;
                } 
    
                if (a.site.level > b.site.level) {
                    return 1;
                }
                
                return 0;
            });
            setNameClass("name");
            setStatusClass("status");
            setLevelClass("level ascend");
            setFloodClass("flood");
            setLastUpdateClass("lastUpdate");

            setSortConfig({...sortConfig,level:false});
            setSites(sites);
        } else {
            sites.sort((a, b) => {
                if(a.site.name < b.site.name) {
                    return 1;
                } 
    
                if (a.site.name > b.site.name) {
                    return -1;
                }
                
                return 0;
            });
            setNameClass("name ascend");
            setStatusClass("status");
            setLevelClass("level descend");
            setFloodClass("flood");
            setLastUpdateClass("lastUpdate");

            setSortConfig({...sortConfig,level:true});
            setSites(sites);
        }
    }

    function sortName(e) {
        console.log("sort by name:",sortConfig);

        if (sortConfig.name == true) {
            sites.sort((a, b) => {
                if(a.name < b.name) {
                    return -1;
                } 
    
                if (a.name > b.name) {
                    return 1;
                }
                
                return 0;
            });
            setNameClass("name descend");
            setStatusClass("status");
            setLevelClass("level");
            setFloodClass("flood");
            setLastUpdateClass("lastUpdate");

            setSortConfig({...sortConfig,name:false});
            setSites(sites);
        } else {
            sites.sort((a, b) => {
                if(a.name < b.name) {
                    return 1;
                } 
    
                if (a.name > b.name) {
                    return -1;
                }
                
                return 0;
            });
            setNameClass("name ascend");
            setStatusClass("status");
            setLevelClass("level");
            setFloodClass("flood");
            setLastUpdateClass("lastUpdate");

            setSortConfig({...sortConfig,name:true});
            setSites(sites);
        }
    }

    function sortStatus(e) {
        console.log("sort by status:",sortConfig);

        if (sortConfig.status == true) {
            sites.sort((a, b) => {
                if(a.active < b.active) {
                    return -1;
                } 
    
                if (a.active > b.active) {
                    return 1;
                }
                
                return 0;
            });
            setNameClass("name");
            setStatusClass("status ascend");
            setLevelClass("level");
            setFloodClass("flood");
            setLastUpdateClass("lastUpdate");

            setSortConfig({...sortConfig,status:false});
            setSites(sites);
        } else {
            sites.sort((a, b) => {
                if(a.active < b.active) {
                    return 1;
                } 
    
                if (a.active > b.active) {
                    return -1;
                }
                
                return 0;
            });
            setNameClass("name");
            setStatusClass("status descend");
            setLevelClass("level");
            setFloodClass("flood");
            setLastUpdateClass("lastUpdate");

            setSortConfig({...sortConfig,status:true});
            setSites(sites);
        }
    }

    return(
        <div className="sitesTable" style={wrapper} onClick={props.onClick}>
            <AppBar title = {countyID + " Sites"} style={appBarStyle}>
                <BackButton style={backLinkStyle} onSelect={() => navigate(-1)}></BackButton>
                <Link style={tableLinkStyle} to={'/SitesByCounty?county=' + countyID}>
                    <GridButton style={gridStyle}></GridButton>
                </Link>
            </AppBar>
            <div className="statusBar" style={statusStyle}>
                <span style={{fontSize:21, marginLeft:10, marginRight:10}}>Total:</span>
                <span style={{fontSize:21}}>{sites.length}</span>
                <span style={{fontSize:21, marginLeft:10, marginRight:10}}>Online:</span>
                <span style={{fontSize:21, color:'rgb(100, 180, 0)'}}>{online}</span>
                <span style={{fontSize:21, marginLeft:10, marginRight:10}}>Offline:</span>
                <span style={{fontSize:21, color:'rgb(255, 0, 0)'}}>{offline}</span>
            </div>
            <SearchBar onChange={onSearch} onClear={onClear} style={searchStyle}></SearchBar>
            <table style={tableStyle} className="table">
                {console.log("SitesTableByCounty:render")}
                <colgroup>
                    <col width="5%"/>
                    <col width="35%"/>
                    <col width="5%"/>
                    <col width="20%"/>
                    <col width="10%"/>
                </colgroup>
                <thead style={{height:36}}>
                    <tr>
                        <th className = {statusClass} style={nameHeaderStyle} onClick={sortStatus}>Status</th>
                        <th className = {nameClass} style={{cursor:"pointer"}} onClick={sortName}>Name</th>
                        <th className = {levelClass} >Level</th>
                        <th className = {floodClass} >Flood</th>
                        <th className = {lastUpdateClass} >Last Update</th>
                    </tr>
                </thead>
                <tbody>
                {
                    sites.map((item,i) => {
                        var active = {"paddingLeft":5};
                        var lastUpdate;
                        var level;
                        var levelMM;
                        var param;
                        var status;
                        var threshold;

                        console.log("Item:",item);
                        sitestyle.display = item.visible;

                        if(item.msg != null) {
                            lastUpdate = new Date(item.lastUpdate).toLocaleString("en-US",{month:"2-digit", day:"2-digit", year:"2-digit", hour:"2-digit", minute:"2-digit", second:"2-digit"})
                            if(item.active) {
                                active.color = "green";
                                status = "Online";
                            } else {
                                active.color = "red";
                                status = "Offline";
                            }

                            if("maxbotix" in item.msg) {
                                param = findParam(item.parameters,'stage');
                                levelMM = (param.elevation - item.msg.maxbotix.mm);
                                level = (levelMM / 304.8).toFixed(2);
                                threshold = findThreshold(param.thresholds,levelMM);
                            } else if("senix" in item.msg) {
                                param = findParam(item.parameters,'stage');
                                levelMM = (param.elevation - item.msg.senix.mm);
                                level = (levelMM / 304.8).toFixed(2);
                                threshold = findThreshold(param.thresholds,levelMM);
                            } else if("csi" in item.msg) {
                                param = findParam(item.parameters,'stage');
                                levelMM = (param.type - item.msg.csi.basic * 304.8);
                                level = (item.msg.csi.basic.ft).toFixed(2);
                                threshold = findThreshold(param.thresholds,levelMM);
                            } else {
                                levelMM = 0;
                                level = 0;
                                threshold = "unknown"
                            }
                        } else {
                            lastUpdate = "--/--/-- --:--";
                            levelMM = 0;
                            level = 0;
                            active.color = "red";
                            status = "Offline";
                            threshold = "unknown"
                        }

                        return(
                            <tr style={{height:36,cursor:"pointer"}} key={i} onClick={(e) => handleRowClick(e,item.site.tags.dashboard,item.site.thingName)} onMouseEnter={(e) => handleMouseEnter(e)} onMouseLeave={(e) => handleMouseLeave(e)}>
                                <td style={active}>{status}</td>
                                <td style={{textAlign:"left"}}>{item.name}</td>
                                <td style={{textAlign:"right"}}>{level + " ft."}</td>
                                <td style={{textAlign:"center"}}>{threshold}</td>
                                <td>{lastUpdate}</td>
                            </tr>
                        )
                    })
                }
                </tbody>
            </table>
        </div>
    )
};