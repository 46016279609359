import React from 'react';
import { useState, useContext, useEffect, useRef } from "react";
import { Link, useSearchParams,useNavigate } from "react-router-dom";

import AppBar from '../components/AppBar.js';
import BackButton from '../components/buttons/BackButton.js';
import DateField from '../components/DateField.js';
import Drawer from '../components/Drawer.js';
import FilledButton from '../components/buttons/FilledButton.js';
import FilterIconButton from '../components/buttons/FilterIconButton.js';
import Grid from '../components/Grid.js';
import GridItem from '../components/GridItem.js';
import InfoIconButton from '../components/buttons/InfoIconButton.js';
import RadioButton from '../components/RadioButton.js';
import Shadow from '../components/Shadow.js';
import StatusCard from '../components/cards/StatusCard.js';

import HomeButton from '../components/buttons/Home.js';


import Chart from "react-apexcharts";
import ApexCharts from 'apexcharts'

import { AuthContext } from '../App';
import { ThemeContext } from '../App';

var stageOptions = {
    chart: {
        id: "stageChart", 
        redrawOnWindowResize: true, 
        animations:{
            enabled:true
        }
    },
    title: {
        text:"Water Level", 
        align: 'center',
        style:{
            fontSize:'18px'
        }
    },
    markers: {hover: {size: 10}},
    dataLabels: {enabled:false},
    colors:['#0080FF'],
    tooltip: {
        x:{
            format:"MM-dd HH:mm:ss"
        }
    },
    xaxis: {type: 'datetime',labels: {format: 'MM-dd HH:mm',datetimeUTC:false}},
    yaxis: {
        labels: {
            formatter: function (value) {
              return value.toFixed(1) + " ft.";
            }
        },
        min:0,
        max:0
    },
    zoom:{
        enabled:true,
        type:"xy",
        autoScaleYaxis:true
    },
    stroke:{
        width:2
    },
    fill:{
        gradient:{
            shade:"light",
            opacityFrom: 0.5,
            opacityTo: 0.5,
        }
    }
}

const voltOptions = {
    chart: {id: "voltChart",
        legend: {
            position: 'top'
        }
    },
    title: {text:"Voltage",align: 'center',style:{fontSize:'18px'},
    },
    markers: {
        // size:5,
        hover: {size: 10}
    },
    dataLabels: {enabled:false},
    colors:['#7851A9'],
    tooltip: {
        x:{
            format:"MM-dd HH:mm:ss"
        }
    },
    xaxis: {type: 'datetime',labels: {format: 'MM-dd HH:mm',datetimeUTC:false}
    },
    yaxis: {
        labels: {
            formatter: function (value) {
              return value.toFixed(2) + " v";
            }
        },
        decimalsInFloat:1
    },
    stroke:{
        width:2
    },
    fill:{
        colors:['#C3B1E1'],
        gradient:{
            shade:"light",
            opacityFrom: 0.5,
            opacityTo: 0.5,
        }
    }
}

var signalOptions = {
    chart: {id: "signalChart",legend: {position: 'top'}},
    title: {text:"Signal Strength",align: 'center',style:{fontSize:'18px'}},
    markers: {hover: {size: 10}},
    dataLabels: {enabled:false},
    colors:['#7851A9'],
    tooltip: {
        x:{
            format:"MM-dd HH:mm:ss"
        }
    },
    xaxis: {type: 'datetime',labels: {format: 'MM-dd HH:mm',datetimeUTC:false}},
    yaxis: {
        labels: {
            formatter: function (value) {
              return value.toFixed(1) + " dB";
            }
        },
        reversed:true,
        min:-120,max:-20
    },
    stroke:{
        width:2
    },
    fill:{
        colors:['#C3B1E1'],
        gradient:{
            shade:"light",
            opacityFrom: 0.5,
            opacityTo: 0.5,
        }
    }
}

const initSite = {
    active:false,
    name:"",
    description:"",
    sensors:[
        {
            make:"",
            model:"",
            value:0
        }
    ],
    location:{
        latitude:0,
        longitude:0
    },
    address:{
        address_1:"",
        address_2:"",
        city:"",
        state:"",
        zip:""
    }
}

var stageParam = {max:0,min:0,thresholds:[]};
var stageValue = 0;

var voltParam = {max:0,min:0,thresholds:[]};
var voltValue = 0;

var signalParam = {max:0,min:0,thresholds:[]};
var signalValue = -0;

var stageSeries = [{name:'stage',data:[]}];
var voltSeries = [{name:'voltage',data:[]}];
var sigSeries = [{name:'signal',data:[]}];

var lastUpdate = "-/-/- -:-:-"

export default function Maxbotix(props) {
    const auths = useContext(AuthContext);
    const theme = useContext(ThemeContext);

    const [dialogVisible, setDialogVisible] = useState(false);
    const [filterState, setFilterState] = useState(false);
    const [shadowState, setShadowState] = useState(false);
    const [site, setSite] = useState(initSite);
    const [update, setUpdate] = useState(0);
    const [lastEvent, setLastEvent] = useState({});
    const [events, setEvents] = useState(initSite);
    const navigate = useNavigate();

    // const [queryParameters] = useSearchParams();
    // const siteID = queryParameters.get("id");

    const url = window.location.href;
    const siteID = url.split("=")[1];

    var startDate = "";
    var endDate = "";

    console.log("maxbotix:props:",props.update);

    function getEvents(start,end) {
        var isoStart = start.toISOString().split("T")[0];
        var isoEnd = end.toISOString().split("T")[0];
        
        console.log("Maxbotix:getEvents:start:",isoStart);
        console.log("Maxbotix:getEvents:end:",isoEnd);

        const stageChart = ApexCharts.getChartByID('stageChart');
        const voltChart = ApexCharts.getChartByID('voltChart');
        const sigChart = ApexCharts.getChartByID('signalChart');

        stageChart.updateSeries([{name: 'Stage',data: []}]);
        voltChart.updateSeries([{name: 'Voltage',data: []}]);
        sigChart.updateSeries([{name: 'Signal',data: []}]);

        stageValue = 0;
        voltValue = 0;
        signalValue = 0;

        const queryStr = {
            query: `query EventsByDate($site:ID!,$start:AWSDate!,$end:AWSDate!){
                getEventsByDate(end: $end, site: $site, start: $start) {
                    nextToken
                    events {
                        msg,
                        pk,
                        sk
                    }
                    site {
                        pk,
                        name,
                        comms,
                        description,
                        thingName,
                        msg,
                        sensors{
                            input,
                            make,
                            model,
                            slot
                        },
                        parameters,
                        lastUpdate,
                        location{
                            latitude,
                            longitude
                        },
                        address{
                            address_1,
                            address_2,
                            city,
                            state,
                            zip
                        }
                    }
                }
            }`,
            variables: {
               site:siteID,
               start:isoStart,
               end:isoEnd
            }
        }

        fetch('https://ebkrbuiepncdzkjpgclwpifxxi.appsync-api.us-east-1.amazonaws.com/graphql',{
            method:'POST',
            headers: {
                "Content-Type":"application/graphql",
                "x-api-key":"da2-6jq5ueouyjdrnnhkk6heqzd7ji"
            },
            body:JSON.stringify(queryStr)
        })
        .then(response => response.json())
        .then(resp => {

            // Convert the params to JSON
            for (var i in resp.data.getEventsByDate.site.parameters) {
                resp.data.getEventsByDate.site.parameters[i] = JSON.parse(resp.data.getEventsByDate.site.parameters[i])
            }

            // Convert Comms to JSON
            resp.data.getEventsByDate.site.comms = JSON.parse(resp.data.getEventsByDate.site.comms);

            // Convert Event Messages to JSON
            for (var i in resp.data.getEventsByDate.events) {
                resp.data.getEventsByDate.events[i].msg = JSON.parse(resp.data.getEventsByDate.events[i].msg);
            }

            console.log("resp:",resp);
            
            setSite(prevSite => {
                return resp.data.getEventsByDate.site;
            });
            console.log("Maxbotix:Site:",resp.data.getEventsByDate.site);

            // Setup stage parameters
            stageParam = resp.data.getEventsByDate.site.parameters.find(p => { return p.type == "stage"});
            console.log("Maxbotix:stage param:",stageParam);
            console.log("Maxbotix:Stage options:",stageOptions);
            stageChart.updateOptions({
                yaxis : {
                    labels: {
                        formatter: function (value) {
                          return value.toFixed(1) + " ft.";
                        }
                    },
                    max:(stageParam.max + stageParam.MLLW) / 304.8,
                    min:(stageParam.min + stageParam.MLLW) / 304.8
                }
            },true);

            // Set stage thresholds
            stageParam.thresholds.forEach(threshold => {
                console.log("Maxbotix:Threshold:",threshold);
                var obj = {
                        y:(threshold.value + stageParam.MLLW) / 304.8,
                        borderColor:threshold.color,
                        fillColor:threshold.color,
                        strokeDashArray:0,
                        label: {
                            borderColor:threshold.color,
                            style: {
                                color: '#FFFFFF',
                                background:threshold.color
                            },
                            text: threshold.name + " (" + ((threshold.value + stageParam.MLLW) / 304.8).toFixed(1) + " ft.)",
                            textAnchor:'start',
                            position:'left',
                            offsetX:10
                        },
                }
                stageChart.addYaxisAnnotation(obj)
            })
            
            // Setup voltage parameters
            voltParam = resp.data.getEventsByDate.site.parameters.find(p => { return p.type == "voltage"});
            console.log("Maxbotix:volt param:",voltParam);
            console.log("Maxbotix:volt options:",voltOptions);
            voltChart.updateOptions({
                yaxis : {
                    labels: {
                        formatter: function (value) {
                          return value.toFixed(1) + " v";
                        }
                    },
                    max:voltParam.max,
                    min:voltParam.min
                }
            },true);

            // Add voltage thresholds
            voltChart.addYaxisAnnotation({
                y:4.0,
                borderColor:"#17CC34",
                fillColor:"#17CC34",
                strokeDashArray:0,
                label: {
                    borderColor: '#17CC34',
                    style: {
                        color: '#FFFFFF',
                        background: '#17CC34'
                    },
                    text: "Excellent",
                    textAnchor:'start',
                    position:'left',
                    offsetX:10
                }
            });

            voltChart.addYaxisAnnotation({
                y:3.7,
                borderColor:"#FECC00",
                fillColor:"#FECC00",
                strokeDashArray:0,
                label: {
                    borderColor: '#FECC00',
                    style: {
                        color: '#FFFFFF',
                        background: '#FECC00'
                    },
                    text: "Good",
                    textAnchor:'start',
                    position:'left',
                    offsetX:10
                }
            });

            voltChart.addYaxisAnnotation({
                y:3.3,
                borderColor:"#FD0101",
                fillColor:"#FD0101",
                strokeDashArray:0,
                label: {
                    borderColor: '#FD0101',
                    style: {
                        color: '#FFFFFF',
                        background: '#FD0101'
                    },
                    text: "Low",
                    textAnchor:'start',
                    position:'left',
                    offsetX:10
                }
            });

            // Setup signal parameters
            signalParam = resp.data.getEventsByDate.site.parameters.find(p => { return p.type == "signal"});
            console.log("Maxbotix:signal param:",signalParam);
            console.log("Maxbotix:signal options:",signalOptions);
            sigChart.updateOptions({
                yaxis : {
                    labels: {
                        formatter: function (value) {
                          return value.toFixed(1) + " dB";
                        }
                    },
                    max:signalParam.max,
                    min:signalParam.min,
                    reversed:true,
                }
            },true);

            // Add signal strength thresholds
            sigChart.addYaxisAnnotation({
                y:-50,
                borderColor:"#17CC34",
                fillColor:"#17CC34",
                strokeDashArray:0,
                label: {
                    borderColor: '#17CC34',
                    style: {
                        color: '#FFFFFF',
                        background: '#17CC34'
                    },
                    text: "Excellent",
                    textAnchor:'start',
                    position:'left',
                    offsetX:10
                }
            });

            sigChart.addYaxisAnnotation({
                y:-70,
                borderColor:"#FECC00",
                fillColor:"#FECC00",
                strokeDashArray:0,
                label: {
                    borderColor: '#FECC00',
                    style: {
                        color: '#000000',
                        background: '#FECC00'
                    },
                    text: "Good",
                    textAnchor:'start',
                    position:'left',
                    offsetX:10
                }
            });

            sigChart.addYaxisAnnotation({
                y:-100,
                borderColor:"#FE8700",
                fillColor:"#FE8700",
                strokeDashArray:0,
                label: {
                    borderColor: '#FE8700',
                    style: {
                        color: '#000000',
                        background: '#FE8700'
                    },
                    text: "Weak",
                    textAnchor:'start',
                    position:'left',
                    offsetX:10
                }
            });

            // Setup Event related components
            console.log("Maxbotix:Events Length:",resp.data.getEventsByDate.events.length);
            if (resp.data.getEventsByDate.events.length === 0) return;

            // Save events
            setEvents(resp.data.getEventsByDate.events);
            // console.log("events:",events);

            // Get last event
            var lastEvent = JSON.parse(resp.data.getEventsByDate.site.msg);
            setLastEvent(lastEvent);

            console.log("Maxbotix:Time:",lastEvent.timestamp);
            lastUpdate = new Date(lastEvent.timestamp * 1000).toLocaleString();
            console.log("Maxbotix:last event:",lastEvent);

            // Setup Stage Value
            stageValue = stageParam.elevation - lastEvent.maxbotix.mm + stageParam.MLLW;

            // Setup voltage value
            if("ina219" in lastEvent) {
                voltValue = lastEvent.ina219.voltage;
            } else if("bq27441" in lastEvent) {
                voltValue = lastEvent.bq27441.voltage;
            }

            // Setup Signal
            if("lte" in lastEvent) {
                signalValue = lastEvent.lte.rssi
            }

            if("wifi" in lastEvent) {
                signalValue = lastEvent.wifi.rssi
            }

            // Load the graph series
            stageSeries = [{name:'stage',data:[]}];
            voltSeries = [{name:'voltage',data:[]}];
            sigSeries = [{name:'signal',data:[]}];

            // var firstDate = resp.data.getEventsByDate.events[0].sk.replace("EVENT#","").substring(0,19);
            // firstDate = firstDate + "Z";
            // var prevDate = new Date(firstDate);
            // console.log("Prev Date:",prevDate);

            // Look for gaps in the data
            // var currTimestamp = 0;
            // var diff = 0;
            // var prevTimestamp = resp.data.getEventsByDate.events[0].msg.timestamp;

            // for(var i=1; i< resp.data.getEventsByDate.events.length; i++) {
            //     currTimestamp = resp.data.getEventsByDate.events[i].msg.timestamp;
            //     diff = prevTimestamp - currTimestamp;


            //     if(diff > 390) {
            //         console.log(prevTimestamp,currTimestamp,diff);
            //     }
            //     prevTimestamp = currTimestamp;
            // }

            // Load messages into the graphs

            resp.data.getEventsByDate.events.forEach(event => {
                var evt = event.msg;
                var dt = (event.sk.replace("EVENT#","") + "Z");
                var dist = ((stageParam.elevation - evt.maxbotix.mm + stageParam.MLLW) / 304.8).toFixed(2);
                
                // console.log("evt:",evt);
                stageSeries[0].data.push({x:dt,y:dist});

                if("ina219" in evt) {
                    voltSeries[0].data.push({x:dt, y:(evt.ina219.voltage).toFixed(2)});
                } else if("bq27441" in evt) {
                    voltSeries[0].data.push({x:dt, y:(evt.bq27441.voltage).toFixed(2)});
                }

                if("lte" in evt) {
                    sigSeries[0].data.push({x:dt,y:(evt.lte.rssi).toFixed(2)});
                }

                if("wifi" in evt) {
                    sigSeries[0].data.push({x:dt,y:(evt.wifi.rssi).toFixed(2)});
                }

                if("GOES" in evt) {
                    sigSeries[0].data.push({x:dt,y:(evt.GOES.signal).toFixed(2)});
                } 
            })

            stageChart.updateSeries(stageSeries,true);
            voltChart.updateSeries(voltSeries,true);
            sigChart.updateSeries(sigSeries,true);
        })
    }

    useEffect(() => {
        let s = new Date();
        let e = new Date();

        s.setDate(e.getDate() - 7);
        e.setDate(e.getDate() + 1);
        getEvents(s,e);
    },[update]);

    const wrapper = {
        width:'100%',
        marginBottom:20
    }

    const backStyle = {
        position:'relative',
        top:-45,
        color:'white'
    }

    const dateStyle = {
        margin:10 
    }

    const dialogStyle = {
        zIndex:100
    }

    const dialogGridStyle = {
        justifyContent:"start",
        gridTemplateColumns: 'auto auto'
    }

    const filledButtonStyle = {
        margin:"auto",
        marginTop:20,
        marginBottom:20
    }

    const filterIcon = {
        position:'absolute',
        right:5,
        top:5
    };

    const gridStyle = {
        marginTop:20
    }

    const InfoIcon = {
        position:'absolute',
        right:45,
        top:5
    };

    const labelStyle = {
        width:300,
        marginTop:20
    }

    const levelStyle = {
        marginTop:20,
        backgroundColor:'rgb(255,255,255)',
        boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.26)"
    }

    const linkStyle = {
        fontSize:21,
        textDecoration: 'none',
        color:'rgb(32,32,32)'
    }

    const radioStyle = {
        marginBottom:20,
        color: "rgb(96, 96, 96)"
    }

    const radioGroupStyle = {
        margin:10
    }

    function getDateFromSK(sk) {
        var firstDate = sk.replace("EVENT#","").substring(0,19) + "Z";
        return  new Date(firstDate);
    }
 
    function onFilterOpen(){
        setShadowState(true);
        setFilterState(true);
    }
    
    function onShadow() {
        setShadowState(false);
        setFilterState(false);
        setDialogVisible(false);
    }

    function onInfoOpen() {
        setShadowState(true);
        setDialogVisible(true);
    }

    function onRadioChange(e) {
        startDate = new Date();
        endDate = new Date();

        startDate.setDate(endDate.getDate() - e.target.value);
        console.log("Maxbotix:onRadioChange:",startDate,endDate);
    };

    function onStartDate(e) {
        startDate = new Date(e.target.value);
        console.log("Maxbotix:onStartDate:",startDate);
    }

    function onEndDate(e) {
        endDate = new Date(e.target.value);
        console.log("Maxbotix:onEndDate:",endDate);
    }

    function onOK(e) {
        endDate.setDate(endDate.getDate() + 1);
        
        getEvents(startDate,endDate);
        setShadowState(false);
        setFilterState(false);
        setDialogVisible(false);
    }

    function onCancel(e) {
        setShadowState(false);
        setFilterState(false);
        setDialogVisible(false);
    }

    function onDialogClose(e) {
        setShadowState(false);
        setFilterState(false);
        setDialogVisible(false);
    }

    let s = new Date();
    let e = new Date();

    return (
        <div className="maxbotix" style={wrapper} onClick={props.onClick}>
            {console.log("Maxbotix:stage:",stageParam,stageValue)}
            {console.log("Maxbotix:voltage:",voltParam,voltValue)}
            {console.log("Maxbotix:signal:",signalParam,signalValue)}

            <Shadow visible = {shadowState} onClose={onShadow}/>
            <Drawer 
                anchor = 'right'
                visible = {filterState} 
                title = "Filter"
            >
                <div style={radioGroupStyle} onChange={onRadioChange}>
                    <RadioButton style={radioStyle} value="1" name="filter" label="Today" />
                    <RadioButton style={radioStyle} value="2" name="filter" label="Last 2 Days" />
                    <RadioButton style={radioStyle} value="7" name="filter" label="Last 7 Days" />
                    <RadioButton style={radioStyle} value="14" name="filter" label="Last 14 Days" />
                    <RadioButton style={radioStyle} value="30" name="filter" label="Last 30 Days" />
                </div>
                <DateField style={dateStyle} onBlur={onStartDate}></DateField>
                <DateField style={dateStyle} onBlur={onEndDate}></DateField>
                <FilledButton style={filledButtonStyle} onSelect={onOK}>Ok</FilledButton>
                <FilledButton style={filledButtonStyle} onSelect={onCancel}>Cancel</FilledButton>
            </Drawer>

            <AppBar title = {site.name} >
                <BackButton style={backStyle} onSelect={() => navigate(-1)}></BackButton>

                <Link style={linkStyle} to={'/Info' + '?id=' + site.pk} >
                    <InfoIconButton style={InfoIcon} />
                </Link>
                <FilterIconButton style={filterIcon} onSelect={onFilterOpen}/>
            </AppBar>
            <div style={{textAlign:"center",marginTop:5}}>
                {lastUpdate.toLocaleString()}
            </div>
            <Grid className="cards" style={gridStyle}>
                <GridItem>
                    <StatusCard 
                        event = {lastEvent}
                        name="Water Level"
                        param = {stageParam}
                        value = {stageValue}
                        unit = 'ft.'
                        status=""
                    />
                </GridItem>
                <GridItem>
                    <StatusCard 
                        event = {lastEvent}
                        name="Voltage"
                        param = {voltParam}
                        value = {voltValue}
                        unit = 'v'
                        status=""
                    />  
                </GridItem>
                <GridItem>
                    <StatusCard 
                        event = {lastEvent}
                        name="Signal Strength"
                        param = {signalParam}
                        value={signalValue}
                        unit = 'dB'
                        status=""
                    />  
                </GridItem>
            </Grid>
            <Chart
                style={levelStyle}
                options={stageOptions}
                series={stageSeries}
                type="area"
                width="100%"
                height="300px"
            />
            <Chart
                style={levelStyle}
                options={voltOptions}
                series={voltSeries}
                type="area"
                width="100%"
                height="300px"
            />
            <Chart
                style={levelStyle}
                options={signalOptions}
                series={sigSeries}
                type="area"
                width="100%"
                height="300px"
            />
        </div>
    )
};