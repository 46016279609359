import React from 'react';
import { useState } from "react";


export default function CommsLabel(props) {

    const wrapper = {
        width:300,
        fontSize:14,
        marginTop:20
    }

    const carrier = {
        marginLeft:5,
        fontWeight:550
    }

    const carrierLabel = {
        marginLeft:30
    }

    const title = {
        marginBottom:5,
        color:"rgb(92,92,92)"
    }

    const type = {
        fontWeight:550
    }

    const typeLabel = {
        marginRight:5
    }

    const text = {
        fontWeight:550
    }

    console.log("CommsLabel:",props);

    if(props.comms == undefined) {
        return (
            <div id="CommsLabel" style={Object.assign(wrapper,props.style)} >
                <div id="title" style={title}>Communications</div>
                <span id="typeLabel" style={typeLabel}>Type:</span>
                <span id="type" style={type}>{"-"}</span>
                <span id="carrierLabel" style={carrierLabel}>Carrier:</span>
                <span id="carrier" style={carrier}>{"-"}</span>
            </div>
        )
    }

    if(props.comms.type == "LTE") {
        return(
            <div id="CommsLabel" style={Object.assign(wrapper,props.style)} >
                <div id="title" style={title}>Communications</div>
                <span id="typeLabel" style={typeLabel}>Type:</span>
                <span id="type" style={type}>{props.comms.type}</span>
                <span id="carrierLabel" style={carrierLabel}>Carrier:</span>
                <span id="carrier" style={carrier}>{props.comms.carrier}</span>
            </div>
        )
    }
}