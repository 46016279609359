import React from 'react';
import { useState } from "react";


export default function SensorsLabel(props) {

    const wrapper = {
        width:300,
        fontSize:14,
        marginTop:20
    }

    const title = {
        marginBottom:5,
        color:"rgb(92,92,92)"
    }

    const text = {
        fontWeight:550
    }

    return(
        <div id="SensorsLabel" style={Object.assign(wrapper,props.style)} >
            <div id="title" style={title}>Sensors</div>
            {
                props.sensors.map((sensor,i) => {
                    let str = sensor.make + " " + sensor.model;
                    return(
                        <div id="text" style={text} key={sensor.model}>{str}</div>
                    )
                })
            }
        </div>
    )
}