import React from 'react';
import { useState, useContext } from "react";
import { ThemeContext } from '../App';

import "./style.css"

export default function RadioButton(props) {
    const theme = useContext(ThemeContext);

    const wrapper = {
        display:'block'
    }

    return (
        <div className={"radioButton"} style={Object.assign(wrapper,props.style)}>
            <label>
                <input type="radio" value={props.value} name={props.name} checked={props.checked}/>
                {props.label}
            </label>
        </div>
    )
}