import React from 'react';
import { useState } from "react";
import ArrowLeftLineIcon from 'remixicon-react/ArrowLeftLineIcon';

export default function BackButton(props) {
    const [isHover, setIsHover] = useState(false);

    const wrapper ={
        width:40,
        height:40,
        borderRadius:'50%',
        backgroundColor: isHover ? 'rgba(192,192,192,0.5)' : 'transparent',
    };

    const icon = {
        fontSize:28,
        marginTop:4,
        marginLeft:4,
        color:'white'
      }

    const mouseEnter = () => {
        setIsHover(true);
    };

    const mouseLeave = () => {
        setIsHover(false);
    };
    
    return(
        <div 
            id = "backButton"
            style={Object.assign(wrapper,props.style)} 
            onClick={props.onSelect}
            onMouseEnter={mouseEnter}
            onMouseLeave={mouseLeave}
            title="Back"
        >
            <ArrowLeftLineIcon id="backIcon" style={icon} size="32px"></ArrowLeftLineIcon>
        </div>

    )
}