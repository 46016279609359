import React from 'react';
import { useState } from "react";
// import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
// import 'primeicons/primeicons.css';
import InformationLineIcon from 'remixicon-react/InformationLineIcon';


export default function InfoIconButton(props) {
    const [isHover, setIsHover] = useState(false);

    const wrapper ={
        width:40,
        height:40,
        borderRadius:'50%',
        backgroundColor: isHover ? 'rgba(240,240,240,0.3)' : 'transparent',
    };

    const icon = {
        fontSize:32,
        marginTop:4,
        marginLeft:4,
        color:'white'
      }

    const mouseEnter = () => {
        setIsHover(true);
    };

    const mouseLeave = () => {
        setIsHover(false);
    };
    
    return(
        <div 
            style={Object.assign(wrapper,props.style)} 
            onClick={props.onSelect}
            onMouseEnter={mouseEnter}
            onMouseLeave={mouseLeave}
        >
            <InformationLineIcon id="InfoIcon" style={icon} size="32px"></InformationLineIcon>
             {/* <i className="pi pi-exclamation-circle" style={icon}></i> */}
            {/* <InfoOutlinedIcon style={icon} /> */}
        </div>

    )
}