import React from "react";
import Stage from "./Stage";
import PrecipSummary from "./PrecipSummary";

const Cards = {
  stage: Stage,
  precipSummary: PrecipSummary,
};

export default (type,site) => {
  if (typeof Cards[type] !== "undefined") {
    return React.createElement(Cards[type], {
      site:site
    });
  }
  return React.createElement(
    () => <div style={{width:300}}>The card {type} has not been created yet.</div>,
    {
      site: site
    }
  );
};