import React from 'react';
import { useState } from "react";


export default function LocationLabel(props) {

    const wrapper = {
        width:400,
        fontSize:14,
        marginTop:20
    }

    const title = {
        marginBottom:5,
        color:"rgb(92,92,92)"
    }

    const latLabel = {
        fontWeight:400,
        color:"rgb(92,92,92)"
    }

    const longLabel = {
        // float:'left',
        fontWeight:400,
        marginLeft:10,
        color:"rgb(92,92,92)"
    }

    const lat = {
        // float:'left',
        fontWeight:550,
        marginLeft:5
    }

    const long = {
        // float:'left',
        fontWeight:550,
        marginLeft:5
    }

    return(
        <div id="LocationLabel" style={Object.assign(wrapper,props.style)} >
            <div id="title" style={title}>Location</div>
            <span id="latLabel" style={latLabel}>Latitude:</span>
            <span id="lat" style={lat}>{props.location.latitude}</span>
            <span id="longLabel" style={longLabel}>Latitude:</span>
            <span id="long" style={long}>{props.location.longitude}</span>
        </div>
    )
}