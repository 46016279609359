import React from 'react';
import { useState, useContext, useEffect, useRef } from "react";
import { Link, useSearchParams, useNavigate } from "react-router-dom";

import AddressLabel from '../components/AddressLabel.js';
import AppBar from '../components/AppBar.js';
import BackButton from '../components/buttons/BackButton.js';
import CommsLabel from '../components/CommsLabel.js';
import DateField from '../components/DateField.js';
import Dialog from '../components/Dialog.js';
import Drawer from '../components/Drawer.js';
import FilledButton from '../components/buttons/FilledButton.js';
import FilterIconButton from '../components/buttons/FilterIconButton.js';
import Grid from '../components/Grid.js';
import GridItem from '../components/GridItem.js';
import InfoIconButton from '../components/buttons/InfoIconButton.js';
import Label from '../components/Label.js';
import LocationLabel from '../components/LocationLabel.js';
import RadioButton from '../components/RadioButton.js';
import SensorsLabel from '../components/SensorsLabel.js';
import Shadow from '../components/Shadow.js';
import StatusCard from '../components/cards/StatusCard.js';

import Chart from "react-apexcharts";
import ApexCharts from 'apexcharts'

import { AuthContext } from '../App';
import { ThemeContext } from '../App';

var stageOptions = {
    chart: {id: "stageChart", redrawOnWindowResize: true, animations:{enabled:true}},
    title: {text:"Water Level", align: 'center',style:{fontSize:'21px'}},
    markers: {hover: {size: 10}},
    dataLabels: {enabled:false},
    colors:['#0080FF'],
    tooltip: {x:{format:"MM-dd HH:mm:ss"}},
    xaxis: {type: 'datetime',labels: {format: 'MM-dd HH:mm',datetimeUTC:false}},
    yaxis: {
        labels: {
            formatter: function (value) {
              return value.toFixed(1) + " ft.";
            }
        },
        min:0,
        max:0
    },
    zoom:{
        enabled:true,
        type:"xy",
        autoScaleYaxis:true
    },
    stroke:{
        width:2
    }
}

const voltOptions = {
    chart: {id: "voltChart",
        legend: {
            position: 'top'
        }
    },
    title: {
        text:"Voltage",
        align: 'center',
        style:{
            fontSize:'21px'
        }
    },
    markers: {
        hover: {size: 10}
    },
    dataLabels: {enabled:false},
    colors:['#7851A9'],
    tooltip: {x:{format:"MM-dd HH:mm:ss"}},
    xaxis: {
        type: 'datetime',
        labels: {
            format: 'MM-dd HH:mm',
            datetimeUTC:false
        }
    },
    yaxis: {
        labels: {
            formatter: function (value) {
              return value.toFixed(2) + " v";
            }
        },
        decimalsInFloat:1
    },
    stroke:{
        width:2
    },
    fill:{
        colors:['#C3B1E1'],
        gradient:{
            shade:"light",
            opacityFrom: 0.5,
            opacityTo: 0.5,
        }
    }
}

var signalOptions = {
    chart: {id: "signalChart",legend: {position: 'top'}},
    title: {text:"Signal Strength",align: 'center',style:{fontSize:'21px'}},
    markers: {hover: {size: 10}},
    dataLabels: {enabled:false},
    colors:['#7851A9'],
    tooltip: {x:{format:"MM-dd hh:mm tt"}},
    xaxis: {type: 'datetime',labels: {format: 'MM-dd hh:mm',datetimeUTC:false}},
    yaxis: {
        labels: {
            formatter: function (value) {
              return value.toFixed(1) + " dB";
            }
        },
        reversed:true,
        min:-120,max:-20
    },
    stroke:{
        width:2
    },
    fill:{
        colors:['#C3B1E1'],
        gradient:{
            shade:"light",
            opacityFrom: 0.5,
            opacityTo: 0.5,
        }
    }
}

const initSite = {
    active:false,
    name:"",
    description:"",
    sensors:[
        {
            make:"",
            model:"",
            value:0
        }
    ],
    location:{
        latitude:0,
        longitude:0
    },
    address:{
        address_1:"",
        address_2:"",
        city:"",
        state:"",
        zip:""
    }
}

var stageParam = {max:0,min:0,thresholds:[]};
var stageValue = 0;

var voltParam = {max:0,min:0,thresholds:[]};
var voltValue = 0;

var signalParam = {max:0,min:0,thresholds:[]};
var signalValue = -0;

var stageSeries = [{name:'stage',data:[]}];
var voltSeries = [{name:'voltage',data:[]}];
var sigSeries = [{name:'signal',data:[]}];

var lastUpdate = "-/-/- -:-:-"

export default function Maxbotix(props) {
    const auths = useContext(AuthContext);
    const theme = useContext(ThemeContext);

    const [dialogVisible, setDialogVisible] = useState(false);
    const [filterState, setFilterState] = useState(false);
    const [shadowState, setShadowState] = useState(false);
    const [site, setSite] = useState(initSite);
    const [update, setUpdate] = useState(0);
    const [lastEvent, setLastEvent] = useState({});
    const [events, setEvents] = useState(initSite);

    // const [queryParameters] = useSearchParams();
    // const siteID = queryParameters.get("id");
    const navigate = useNavigate();

    const url = window.location.href;
    const siteID = url.split("=")[1];

    var startDate = "";
    var endDate = "";

    console.log("maxbotix:props:",props.update);

    function getEvents(start,end) {
        const isoStart = start.toISOString().split("T")[0];
        const isoEnd = end.toISOString().split("T")[0];
        
        console.log("Maxbotix:getEvents:start:",isoStart);
        console.log("Maxbotix:getEvents:end:",isoEnd);

        const stageChart = ApexCharts.getChartByID('stageChart');
        const voltChart = ApexCharts.getChartByID('voltChart');
        const sigChart = ApexCharts.getChartByID('signalChart');

        stageValue = 0;
        voltValue = 0;
        signalValue = 0;

        const queryStr = {
            query: `query EventsByDate($site:ID!,$start:AWSDate!,$end:AWSDate!){
                getEventsByDate(end: $end, site: $site, start: $start) {
                    nextToken
                    events {
                        msg,
                        pk,
                        sk
                    }
                    site {
                        pk,
                        name,
                        comms,
                        description,
                        thingName,
                        msg,
                        sensors{
                            input,
                            make,
                            model,
                            slot
                        },
                        parameters,
                        lastUpdate,
                        location{
                            latitude,
                            longitude
                        },
                        address{
                            address_1,
                            address_2,
                            city,
                            state,
                            zip
                        }
                    }
                }
            }`,
            variables: {
               site:siteID,
               start:isoStart,
               end:isoEnd
            }
        }

        fetch('https://ebkrbuiepncdzkjpgclwpifxxi.appsync-api.us-east-1.amazonaws.com/graphql',{
            method:'POST',
            headers: {
                "Content-Type":"application/graphql",
                "x-api-key":"da2-6jq5ueouyjdrnnhkk6heqzd7ji"
            },
            body:JSON.stringify(queryStr)
        })
        .then(response => response.json())
        .then(resp => {

            // Convert params to JSON
            for (var i in resp.data.getEventsByDate.site.parameters) {
                resp.data.getEventsByDate.site.parameters[i] = JSON.parse(resp.data.getEventsByDate.site.parameters[i])
            }

            // Convert comms to JSON
            resp.data.getEventsByDate.site.comms = JSON.parse(resp.data.getEventsByDate.site.comms);

            // Save the site
            setSite(prevSite => {
                return resp.data.getEventsByDate.site;
              });
            console.log("Site:",resp.data.getEventsByDate.site);

            // Setup stage parameters
            stageParam = resp.data.getEventsByDate.site.parameters.find(p => { return p.type == "stage"});
            console.log("Radar:stage param:",stageParam);
            console.log("Radar:Stage options:",stageOptions);
            stageChart.updateOptions({
                yaxis : {
                    labels: {
                        formatter: function (value) {
                          return value.toFixed(1) + " ft.";
                        }
                    },
                    max:stageParam.max + stageParam.MLLW,
                    min:stageParam.min + stageParam.MLLW
                }
            },true);

            // Add stage thresholds
            stageParam.thresholds.forEach(threshold => {
                console.log("Maxbotix:Threshold:",threshold);
                var obj = {
                        y:(threshold.value + stageParam.MLLW),
                        borderColor:threshold.color,
                        fillColor:threshold.color,
                        strokeDashArray:0,
                        label: {
                            borderColor:threshold.color,
                            style: {
                                color: '#FFFFFF',
                                background:threshold.color
                            },
                            text: threshold.name + " (" + (threshold.value + stageParam.MLLW).toFixed(1) + " ft.)",
                            textAnchor:'start',
                            position:'left',
                            offsetX:10
                        },
                }
                stageChart.addYaxisAnnotation(obj)
            })

            // Setup voltage parameters
            voltParam = resp.data.getEventsByDate.site.parameters.find(p => { return p.type == "voltage"});
            console.log("Radar:volt param:",voltParam);
            console.log("Radar:voltage options:",voltOptions);
            voltChart.updateOptions({
                yaxis : {
                    labels: {
                        formatter: function (value) {
                          return value.toFixed(1) + " v";
                        }
                    },
                    max:voltParam.max,
                    min:voltParam.min
                }
            },true);

            // Add voltage thresholds
            voltChart.addYaxisAnnotation({
                y:13.5,
                borderColor:"#17CC34",
                fillColor:"#17CC34",
                strokeDashArray:0,
                label: {
                    borderColor: '#17CC34',
                    style: {
                        color: '#FFFFFF',
                        background: '#17CC34'
                    },
                    text: "Excellent",
                    textAnchor:'start',
                    position:'left',
                    offsetX:10
                }
            });

            voltChart.addYaxisAnnotation({
                y:12.5,
                borderColor:"#FECC00",
                fillColor:"#FECC00",
                strokeDashArray:0,
                label: {
                    borderColor: '#FECC00',
                    style: {
                        color: '#FFFFFF',
                        background: '#FECC00'
                    },
                    text: "Good",
                    textAnchor:'start',
                    position:'left',
                    offsetX:10
                }
            });

            voltChart.addYaxisAnnotation({
                y:11,
                borderColor:"#FD0101",
                fillColor:"#FD0101",
                strokeDashArray:0,
                label: {
                    borderColor: '#FD0101',
                    style: {
                        color: '#FFFFFF',
                        background: '#FD0101'
                    },
                    text: "Low",
                    textAnchor:'start',
                    position:'left',
                    offsetX:10
                }
            });

            // Setup signal parameters
            signalParam = resp.data.getEventsByDate.site.parameters.find(p => { return p.type == "signal"});
            console.log("signal param:",signalParam);
            console.log("Radar:signal options:",signalOptions);
            sigChart.updateOptions({
                yaxis : {
                    labels: {
                        formatter: function (value) {
                          return value.toFixed(1) + " dB";
                        }
                    },
                    max:signalParam.max,
                    min:signalParam.min,
                    reversed:true,
                }
            },true);

            // Add signal strength thresholds
            sigChart.addYaxisAnnotation({
                y:-50,
                borderColor:"#17CC34",
                fillColor:"#17CC34",
                strokeDashArray:0,
                label: {
                    borderColor: '#17CC34',
                    style: {
                        color: '#FFFFFF',
                        background: '#17CC34'
                    },
                    text: "Excellent",
                    textAnchor:'start',
                    position:'left',
                    offsetX:10
                }
            });

            sigChart.addYaxisAnnotation({
                y:-70,
                borderColor:"#FECC00",
                fillColor:"#FECC00",
                strokeDashArray:0,
                label: {
                    borderColor: '#FECC00',
                    style: {
                        color: '#000000',
                        background: '#FECC00'
                    },
                    text: "Good",
                    textAnchor:'start',
                    position:'left',
                    offsetX:10
                }
            });

            sigChart.addYaxisAnnotation({
                y:-100,
                borderColor:"#FE8700",
                fillColor:"#FE8700",
                strokeDashArray:0,
                label: {
                    borderColor: '#FE8700',
                    style: {
                        color: '#000000',
                        background: '#FE8700'
                    },
                    text: "Weak",
                    textAnchor:'start',
                    position:'left',
                    offsetX:10
                }
            });

            // Setup Event related components
            console.log("Radar:Events Length:",resp.data.getEventsByDate.events.length);
            if (resp.data.getEventsByDate.events.length === 0) return;

            // Save events
            setEvents(resp.data.getEventsByDate.events);

            // Get last event
            var lastEvent = JSON.parse(resp.data.getEventsByDate.site.msg);
            setLastEvent(lastEvent);

            console.log("Radar:Time:",lastEvent.timestamp);
            lastUpdate = new Date(lastEvent.timestamp * 1000).toLocaleString();
            console.log("Radar:Last event:",lastEvent);

            // Setup Stage Value
            stageValue = stageParam.elevation - lastEvent.csi.basic.ft + stageParam.MLLW;
            console.log("Stage Value:",stageValue);

            // Setup voltage value
            if("ina219" in lastEvent) {
                voltValue = lastEvent.ina219.voltage;
            } else {
                voltValue = lastEvent.csi.basic.voltage;
            }

            // Setup Signal
            if("lte" in lastEvent) {
                signalValue = lastEvent.lte.rssi
            }

            if("wifi" in lastEvent) {
                signalValue = lastEvent.wifi.rssi
            }

            // Load the graph series
            stageSeries = [{name:'stage',data:[]}];
            voltSeries = [{name:'voltage',data:[]}];
            sigSeries = [{name:'signal',data:[]}];

            resp.data.getEventsByDate.events.forEach(event => {
                var evt = JSON.parse(event.msg);
                var dt = event.sk.replace("EVENT#","")
                dt += "Z";

                console.log("evt:",evt);

                if("csi" in evt) {
                    var dist = (stageParam.elevation - evt.csi.basic.ft + stageParam.MLLW).toFixed(2);
                    stageSeries[0].data.push({x:dt,y:dist});
                }

                if("ina219" in evt) {
                    voltSeries[0].data.push({x:dt, y:(evt.ina219.voltage).toFixed(2)});
                } else {
                    voltSeries[0].data.push({x:dt, y:(evt.csi.basic.voltage).toFixed(2)});
                }

                if("lte" in evt) {
                    sigSeries[0].data.push({x:dt,y:(evt.lte.rssi).toFixed(2)});
                }

                if("wifi" in evt) {
                    sigSeries[0].data.push({x:dt,y:(evt.wifi.rssi).toFixed(2)});
                }
            })

            stageChart.updateSeries(stageSeries,true);
            voltChart.updateSeries(voltSeries,true);
            sigChart.updateSeries(sigSeries,true);
        })
    }

    useEffect(() => {
        let s = new Date();
        let e = new Date();

        console.log("Maxbotix useEffect:start");
        s.setDate(e.getDate() - 7);
        e.setDate(e.getDate() + 1);
        getEvents(s,e);

        console.log("Maxbotix useEffect:end");
    },[props]);

    const wrapper = {
        width:'100%'
    }

    const backStyle = {
        position:'relative',
        top:-45,
        color:'white'
    }

    const dateStyle = {
        margin:10 
    }

    const dialogStyle = {
        zIndex:100
    }

    const dialogGridStyle = {
        justifyContent:"start",
        gridTemplateColumns: 'auto auto'
    }

    const gridStyle = {
        marginTop:20
    }

    const levelStyle = {
        marginTop:20,
        backgroundColor:'rgb(255,255,255)',
        boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.26)"
    }

    const radioStyle = {
        marginBottom:20,
        color: "rgb(96, 96, 96)"
    }

    const radioGroupStyle = {
        margin:10
    }

    const filledButtonStyle = {
        margin:"auto",
        marginTop:20,
        marginBottom:20
    }

    const filterIcon = {
        position:'absolute',
        right:5,
        top:5
    };

    const InfoIcon = {
        position:'absolute',
        right:50,
        top:5
    };

    const labelStyle = {
        width:300,
        marginTop:20
    }

    const linkStyle = {
        fontSize:21,
        textDecoration: 'none',
        color:'rgb(32,32,32)'
    }

    function onFilterOpen(){
        setShadowState(true);
        setFilterState(true);
    }
    
    function onShadow() {
        setShadowState(false);
        setFilterState(false);
        setDialogVisible(false);
    }

    function onInfoOpen() {
        setShadowState(true);
        setDialogVisible(true);
    }

    function onRadioChange(e) {
        startDate = new Date();
        endDate = new Date();

        startDate.setDate(endDate.getDate() - e.target.value);
        console.log("onRadioChange:",startDate,endDate);
    };

    function onStartDate(e) {
        startDate = new Date(e.target.value);
        console.log("onStartDate:",startDate);
    }

    function onEndDate(e) {
        endDate = new Date(e.target.value);
        console.log("onEndDate:",endDate);
    }

    function onOK(e) {
        endDate.setDate(endDate.getDate() + 1);
        
        getEvents(startDate,endDate);
        setShadowState(false);
        setFilterState(false);
        setDialogVisible(false);
    }

    function onCancel(e) {
        setShadowState(false);
        setFilterState(false);
        setDialogVisible(false);
    }

    function onDialogClose(e) {
        setShadowState(false);
        setFilterState(false);
        setDialogVisible(false);
    }

    let s = new Date();
    let e = new Date();

    return (
        <div className="radar" style={wrapper} onClick={props.onClick}>
            {console.log("stage:",stageParam,stageValue)}
            {console.log("voltage:",voltParam,voltValue)}
            {console.log("signal:",signalParam,signalValue)}

            <Dialog 
                style={dialogStyle} 
                visible={dialogVisible}
                title="Site Overview"
                onClose = {onDialogClose}
            >
                <Grid style={dialogGridStyle}>
                    <GridItem>
                        <Label title="Name">{site.name}</Label>
                        <Label title="Description" style={labelStyle}>{site.description}</Label>
                        <LocationLabel location={site.location} />
                        <AddressLabel address={site.address}/>
                        <SensorsLabel sensors={site.sensors}></SensorsLabel>
                        <CommsLabel comms={site.comms}></CommsLabel>
                    </GridItem>
                    <GridItem>
                        <Label title="Stage"></Label>
                        <span style={{marginTop:5,fontSize:14, color:"rgb(92,92,92"}}>Min:</span>
                        <span style={{marginLeft:5,fontSize:14, fontWeight:550}}>{(stageParam.min / 304.8).toFixed(2)}</span>
                        <span style={{marginLeft:5,fontSize:14, fontWeight:550}}>ft.</span>

                        <span style={{marginTop:5,marginLeft:10,fontSize:14, color:"rgb(92,92,92"}}>Max:</span>
                        <span style={{marginLeft:5,fontSize:14, fontWeight:550}}>{(stageParam.max / 304.8).toFixed(2)}</span>
                        <span style={{marginLeft:5,fontSize:14, fontWeight:550}}>ft.</span>

                        <div style={{marginTop:5,marginBottom:5,fontSize:14,color:"rgb(92,92,92"}}>Thresholds:</div>
                        {
                            stageParam.thresholds.map((threshold,i) => {
                                var str = (threshold.value / 304.8).toFixed(2) + " ft. - " + threshold.name;
                                return (
                                    <div key={i} style={{fontSize:14,fontWeight:550}}>{str}</div>
                                )
                            })
                        }

                        <Label title="Voltage" style={{marginTop:20}}></Label>
                        <span style={{marginTop:5,fontSize:14, color:"rgb(92,92,92"}}>Min:</span>
                        <span style={{marginLeft:5,fontSize:14, fontWeight:550}}>{voltParam.min}</span>
                        <span style={{marginLeft:5,fontSize:14, fontWeight:550}}>v</span>

                        <span style={{marginTop:5,marginLeft:10,fontSize:14, color:"rgb(92,92,92"}}>Max:</span>
                        <span style={{marginLeft:5,fontSize:14, fontWeight:550}}>{voltParam.max}</span>
                        <span style={{marginLeft:5,fontSize:14, fontWeight:550}}>v</span>

                        <Label title="Signal" style={{marginTop:20}}></Label>
                        <span style={{marginTop:5,fontSize:14, color:"rgb(92,92,92"}}>Min:</span>
                        <span style={{marginLeft:5,fontSize:14, fontWeight:550}}>{signalParam.min}</span>
                        <span style={{marginLeft:5,fontSize:14, fontWeight:550}}>dB</span>

                        <span style={{marginTop:5,marginLeft:10,fontSize:14, color:"rgb(92,92,92"}}>Max:</span>
                        <span style={{marginLeft:5,fontSize:14, fontWeight:550}}>{signalParam.max}</span>
                        <span style={{marginLeft:5,fontSize:14, fontWeight:550}}>dB</span>
                    
                    </GridItem>
                </Grid>
            </Dialog>
            <Shadow visible = {shadowState} onClose={onShadow}/>
            <Drawer 
                anchor = 'right'
                visible = {filterState} 
                title = "Filter"
            >
                <div style={radioGroupStyle} onChange={onRadioChange}>
                    <RadioButton style={radioStyle} value="1" name="filter" label="Today" />
                    <RadioButton style={radioStyle} value="2" name="filter" label="Last 2 Days" />
                    <RadioButton style={radioStyle} value="7" name="filter" label="Last 7 Days" />
                    <RadioButton style={radioStyle} value="14" name="filter" label="Last 14 Days" />
                    <RadioButton style={radioStyle} value="30" name="filter" label="Last 30 Days" />
                </div>
                <DateField style={dateStyle} onBlur={onStartDate}></DateField>
                <DateField style={dateStyle} onBlur={onEndDate}></DateField>
                <FilledButton style={filledButtonStyle} onSelect={onOK}>Ok</FilledButton>
                <FilledButton style={filledButtonStyle} onSelect={onCancel}>Cancel</FilledButton>
            </Drawer>

            <AppBar title = {site.name} >
                <BackButton style={backStyle} onSelect={() => navigate(-1)}></BackButton>
                <Link style={linkStyle} to={'/Info' + '?id=' + site.pk} >
                    <InfoIconButton style={InfoIcon} />
                </Link>                
                <FilterIconButton style={filterIcon} onSelect={onFilterOpen}/>
            </AppBar>
            <div style={{textAlign:"center",marginTop:5}}>
                {lastUpdate.toLocaleString()}
            </div>
            <Grid className="cards" style={gridStyle}>
                <GridItem>
                    <StatusCard 
                        name="Water Level"
                        param = {stageParam}
                        value = {stageValue}
                        unit = 'ft'
                        status=""
                    />
                </GridItem>
                <GridItem>
                    <StatusCard 
                        name="Voltage"
                        param = {voltParam}
                        value = {voltValue}
                        unit = 'v'
                        status=""
                    />  
                </GridItem>
                <GridItem>
                    <StatusCard 
                        name="Signal Strength"
                        param = {signalParam}
                        value={signalValue}
                        unit = 'dB'
                        status=""
                    />  
                </GridItem>
            </Grid>
            <Chart
                style={levelStyle}
                options={stageOptions}
                series={stageSeries}
                type="area"
                width="100%"
                height="300px"
            />
            <Chart
                style={levelStyle}
                options={voltOptions}
                series={voltSeries}
                type="area"
                width="100%"
                height="300px"
            />
            <Chart
                style={levelStyle}
                options={signalOptions}
                series={sigSeries}
                type="area"
                width="100%"
                height="300px"
            />
        </div>
    )
};