import React from 'react';
import { useState, useContext } from "react";
import SignalIcon from 'remixicon-react/SignalTowerLineIcon';
import { ThemeContext } from '../../../App.js';
import "../../style.css";

export default function Signal(props) {
    const theme = useContext(ThemeContext);

    const icon = {
        position:'absolute',
        top:5,
        left:5,
        width:16,
        height:16,
        color:'rgb(25, 145, 220)'
    }

    const nameStyle = {
        marginLeft:20
    }

    const valueStyle = {
        display:'inline',
        fontSize:32,
        fontWeight:"bold",
        marginTop:5
    }

    const valueWrapper = {
        position:'relative',
        width:'100%',
        textAlign:'center',
        marginTop:10
    }

    const unitStyle = {
        display:'inline',
        fontSize:16,
        marginLeft:5
    }

    function findParam(name) {
        var params;
        console.log("Signal:findParam:",name);

        for (var i in props.site.parameters) {
            if(props.site.parameters[i].type == name) {
                return props.site.parameters[i];
            }
        }
        console.log("Signal:unknown type:",name);
        return null;
    }

    // Setup
    var tStatus = "Normal";
    // var param;
    var message = props.site.message;
    var value;

    console.log("Signal:site:",props.site);
    console.log("Signal:reading:",props.reading);

    if(message != null) {
        if(message.lte !== null) {
            value = (message.lte.rssi).toFixed(1);
        }
        
        props.reading.thresholds.forEach( threshold => {
            if(value > threshold.value) {
                tStatus = threshold.name;
            }
        })

        console.log(tStatus);

    } else {
        console.log("Signal:No Message");
        value = 0;
        tStatus = "No Message";
    }

    return (
        <div className="card signal">
            <SignalIcon id="signalIcon" style={icon}></SignalIcon>
            <div className="name" style={nameStyle}>{props.reading.name}</div>
            <div className="wrapper">
                <div className="reading">{value}</div>
                <div className="unit">dBm</div>
            </div>
        </div>
    )
}