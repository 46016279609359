import React from 'react';
import { useState } from "react";
import Filter3FillIcon from 'remixicon-react/Filter3FillIcon';

export default function FilterIconButton(props) {
    const [isHover, setIsHover] = useState(false);

    const wrapper ={
        width:40,
        height:40,
        borderRadius:'50%',
        backgroundColor: isHover ? 'rgba(240,240,240,0.3)' : 'transparent',
    };

    const icon = {
        fontSize:28,
        marginTop:4,
        marginLeft:4,
        color:'white'
      }

    const mouseEnter = () => {
        setIsHover(true);
    };

    const mouseLeave = () => {
        setIsHover(false);
    };
    
    return(
        <div 
            id = "filterButton"
            style={Object.assign(wrapper,props.style)} 
            onClick={props.onSelect}
            onMouseEnter={mouseEnter}
            onMouseLeave={mouseLeave}
        >
            <Filter3FillIcon id="filterIcon" style={icon} size="32px"></Filter3FillIcon>
        </div>

    )
}