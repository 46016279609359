import React from 'react';
import { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import AppBar from '../components/AppBar.js';
import Grid from '../components/Grid.js';
import GridItem from '../components/GridItem.js';
import { AuthContext } from '../App';
import { ThemeContext } from '../App';
import { urlContext } from '../App';
import { keyContext } from '../App';

import "./style.css";

export default function Home(props) {
    // const auths = useContext(AuthContext);
    const theme = useContext(ThemeContext);
    const asURL = useContext(urlContext);
    const asKey = useContext(keyContext);
    const [customers, setCustomers] = useState([]);
    const navigate = useNavigate();

    console.log("Home:",props);

    useEffect(() => {
        const queryStr = {
            query: `
            query get_customers {
                getCustomers {
                    customers {
                        active,
                        name,
                        description,
                        abbreviation,
                        pk,
                        sk
                    }
                    nextToken
                }
            }     
            `,
            variables: {
            }
        }
        fetch(asURL,{
            method:'POST',
            headers: {
                "Content-Type":"application/graphql",
                "x-api-key":asKey
            },
            body:JSON.stringify(queryStr)
        })
        .then(response => response.json())
        .then(resp => {
            var cust;

            console.log("Home:resp:",resp.data.getCustomers.customers);
            cust = resp.data.getCustomers.customers.sort((a, b) => {
                if(a.name < b.name) {
                    return -1;
                } 

                if (a.name > b.name) {
                    return 1;
                }
                
                return 0;
            });

            console.log("Customers:",cust);
            setCustomers(cust);
        })
    }, [props.update]);

    const wrapper = {
        width:'100%'
    }

    const gridStyle = {
        marginTop:80
    }

    const linkStyle = {
        fontSize:21,
        textDecoration: 'none',
        color:'rgb(32,32,32)'
    }

    return (
        <div className="home" style={wrapper} onClick={props.onClick}>
            <AppBar title = "Green Stream Dashboards" />
            <Grid style={gridStyle} className="grid">
                {
                    customers.map((customer,i) => {
                        console.log("customer:",customer);
                        if(customer.active) {
                            return(
                                <GridItem key={customer.name} >
                                    <Link style={linkStyle} to={'/Sites/Grid/?id=' + customer.sk} >
                                        <div className={"customer"}>
                                            <div style={{textAlign:"center"}}>{customer.abbreviation}</div>
                                        </div>
                                    </Link>
                                </GridItem>
                            )
                        }
                    })
                }
            </Grid>
        </div>
    )
};
