import React from 'react';
import { forwardRef } from 'react';
import { useState, useContext } from "react";
import { ThemeContext } from '../App';

const itemStyle = {

}

export default forwardRef(function GridItem(props,ref) {
    return (
        <div id="gridItem" ref={ref}>
            {props.children}
        </div>
    )
});
