import React from 'react';
import { useState } from "react";


export default function Label(props) {

    const wrapper = {
        width:200,
        fontSize:14,
    }

    const title = {
        marginBottom:5,
        color:"rgb(92,92,92)"
    }

    const text = {
        fontWeight:550,
        color:"rgb(0,0,0)"
    }

    return(
        <div id="label" style={Object.assign(wrapper,props.style)} >
            <div id="title" style={title}>{props.title}</div>
            <div id="text" style={text}>{props.children}</div>
        </div>
    )
}