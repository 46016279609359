import React from 'react';
import { useState, useContext } from "react";
import { ThemeContext } from '../App';

export default function Spinner(props) {
    const theme = useContext(ThemeContext);
    const wrapperStyle = {
      position:'fixed',
      width:200,
      height:200,
      backgroundColor:'white',
      borderRadius:100,
      margin:0,
      top:'50%',
      left:'50%',
      transform: 'translate(-50%, -50%)',
      // boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.26)',
      visibility:'visiable'
    }

    console.log("Spinnser:visible:",props.visible);

    if(props.visible) {
      wrapperStyle.visibility = 'visible'
    } else {
      wrapperStyle.visibility = 'hidden'
    }

    return (
        <div className={props.className} style={Object.assign(wrapperStyle,props.style)} >
            <svg width="200" height="200" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <circle cx="12" cy="12" r="0" fill="#1991DC">
                <animate id="spinner_lNK6" begin="0;spinner_NDtD.begin+0.6s" attributeName="r" calcMode="spline" dur="2s" values="0;12" keySplines=".52,.6,.25,.99" fill="freeze"/>
                <animate begin="0;spinner_NDtD.begin+0.6s" attributeName="opacity" calcMode="spline" dur="2s" values="1;0" keySplines=".52,.6,.25,.99" fill="freeze"/>
              </circle>
              <circle cx="12" cy="12" r="0" fill='#1991DC'>
                <animate id="spinner_NDtD" begin="spinner_lNK6.begin+0.6s" attributeName="r" calcMode="spline" dur="2s" values="0;12" keySplines=".52,.6,.25,.99" fill="freeze"/>
                <animate begin="spinner_lNK6.begin+0.6s" attributeName="opacity" calcMode="spline" dur="2s" values="1;0" keySplines=".52,.6,.25,.99" fill="freeze"/>
              </circle>
              </svg>
        </div>
    )
}

