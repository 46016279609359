import React from 'react';
import { useState, useContext, useEffect, useRef } from "react";
import { Link,useSearchParams } from "react-router-dom";

import AppBar from '../components/AppBar.js';
import Grid from '../components/Grid.js';
import GridItem from '../components/GridItem.js';
import Cards from "../components/cards/overview/Cards.js";
import SearchBar from '../components/SearchBar.js';
import BackButton from '../components/buttons/BackButton.js';
import TableButton from '../components/buttons/Table.js';
import { AuthContext } from '../App';
import { ThemeContext } from '../App';

var online = 0;
var offline = 0;

export default function SitesByOccupant(props) {
    // const auths = useContext(AuthContext);
    const theme = useContext(ThemeContext);
    const elemRef = useRef([]);
    const [occupant, setOccupant] = useState([]);
    const [sites, setSites] = useState([]);
    const [queryParameters] = useSearchParams();
    const url = window.location.href;
    const occupantID = url.split("=")[1];

    {console.log("Sites:Theme:",theme)}

    useEffect(() => {
        console.log("occupant:",occupantID);
        const queryStr = {
            query: `query SitesByOccupant($id: ID!) {
                SitesByOccupant(id: $id) {
                    occupant {
                        name
                    }
                    sites {
                        active,
                        enabled,
                        lastUpdate
                        name,
                        msg,
                        parameters,
                        pk,
                        tags
                    }
                }
            }`,
            variables: {
                "id":occupantID
            }
        }

        console.log(JSON.stringify(queryStr));

        fetch('https://ebkrbuiepncdzkjpgclwpifxxi.appsync-api.us-east-1.amazonaws.com/graphql',{
            method:'POST',
            headers: {
                "Content-Type":"application/graphql",
                "x-api-key":"da2-6jq5ueouyjdrnnhkk6heqzd7ji"
            },
            body:JSON.stringify(queryStr)
        })
        .then(response => response.json())
        .then(resp => {
            var sites;

            online = 0;
            offline = 0;

            console.log("resp:",resp);
            console.log("resp:",resp.data.SitesByOccupant);

            if(resp.data.SitesByOccupant.sites != null) {

                resp.data.SitesByOccupant.sites.forEach(item => {
                    item.msg = JSON.parse(item.msg);
                    item.tags = JSON.parse(item.tags);
                    for(var i in item.parameters) {
                        item.parameters[i] = JSON.parse(item.parameters[i]);
                    }
                    item["visible"] = 'block';

                    if(item.active) {
                        online += 1;
                    } else {
                        offline += 1;
                    }
                })
            
                sites = resp.data.SitesByOccupant.sites.sort((a, b) => {
                    if(a.name < b.name) {
                        return -1;
                    } 

                    if (a.name > b.name) {
                        return 1;
                    }
                    
                    return 0;
                });

                console.log("Sites:",sites);
                setSites(sites);
                setOccupant(resp.data.SitesByOccupant.occupant);
            }
        })
    }, [props.update]);

    const wrapper = {
        width:'100%',
        marginBottom:40
    }

    const appBarStyle = {
        position:'fixed',
        top:0,
        zIndex:1
    }

    const backStyle = {
        color:'white'
    }

    const backLinkStyle = {
        position:'absolute',
        top:4,
        left:5,
        fontSize:21,
        textDecoration: 'none',
        color:'rgb(32,32,32)'
    }

    const gridStyle = {
        marginTop:165
    }

    const itemStyle = {
        display:"block"
    }

    const linkStyle = {
        fontSize:21,
        textDecoration: 'none',
        color:'rgb(32,32,32)'
    }

    const searchStyle = {
        position:'fixed',
        top:96,
        zIndex:1
    }

    const statusStyle = {
        position:'fixed',
        top:48,
        left:0,
        right:0,
        height:48,
        background:'rgb(255,255,255)',
        lineHeight:"48px",
        borderBottom:"solid 1px rgb(240,240,240)",
        textAlign :'center',
        zIndex:1
    }

    const tableLinkStyle = {
        position:'absolute',
        top:4,
        right:5,
        fontSize:21,
        textDecoration: 'none'
    }

    const tableStyle = {
        position:'relative',
        color:'white'
    }

    function onClear(e) {
        for(var i in elemRef.current) {
            elemRef.current[i].style.display = "block";
        }
    }

    function onSearch(e) {
        var tmp = sites;

        console.log("onSearch:",e.target.value);
        for(var i in sites) {
            if(sites[i].site.name.toLowerCase().startsWith(e.target.value.toLowerCase())) {
                elemRef.current[i].style.display = "block";
                console.log(elemRef.current[i]);
            } else {
               elemRef.current[i].style.display = "none";
            }
        }
    }

    return(
        <div className="sites" style={wrapper} onClick={props.onClick}>
            <AppBar title = {occupant.name + " Sites"} style={appBarStyle}>
                <Link style={backLinkStyle} to="/">
                    <BackButton style={backStyle}></BackButton>
                </Link>
                <Link style={tableLinkStyle} to={"/SitesTableByCounty?county=" + occupantID}>
                    <TableButton style={tableStyle}></TableButton>
                </Link>
            </AppBar>
            <div className="statusBar" style={statusStyle}>
                <span style={{fontSize:21, marginLeft:10, marginRight:10}}>Total:</span>
                <span style={{fontSize:21}}>{sites.length}</span>
                <span style={{fontSize:21, marginLeft:10, marginRight:10}}>Online:</span>
                <span style={{fontSize:21, color:'rgb(100, 180, 0)'}}>{online}</span>
                <span style={{fontSize:21, marginLeft:10, marginRight:10}}>Offline:</span>
                <span style={{fontSize:21, color:'rgb(255, 0, 0)'}}>{offline}</span>
            </div>
            <SearchBar onChange={onSearch} onClear={onClear} style={searchStyle}></SearchBar>
            <Grid style={gridStyle} className="cards">
                {console.log("Sites:render")}
                {
                    sites.map((site,i) => {
                        itemStyle.display = site.visible;
                        if(site.enabled) {
                            return(
                                <GridItem key={i} style={itemStyle} ref={(el) => (elemRef.current[i] = el)}  >
                                    <Link style={linkStyle} to={'/' + site.tags.dashboard + '?id=' + site.pk} >
                                        {Cards(site.tags.summary + "Summary",site)}
                                    </Link>
                                </GridItem>
                            )
                        }
                    })
                }
            </Grid>
        </div>
    )
};